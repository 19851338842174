// COMPONENTS
import Configuration from "./email/Configuration";
import Customization from "./email/Customization";

export default function Email() {
  return (
    <div>
      <Configuration />

      <br />

      <Customization />
    </div>
  );
}
