const configuration = {
  title: "Configuration",

  tabs: {
    email: {
      title: "Configuration de l'envoi des mails",
      customization: {
        title: "Personnalisation",
        fields: {
          name: {
            label: "Nom de l'expéditeur",
            placeholder: "Nom de l'expéditeur",
          },
          logo: { label: "Image d'en-tête", placeholder: "Choisir une image" },
          sendingEmail: {
            label: "Email expéditrice",
            placeholder: "Email expéditrice",
          },
          signature: {
            label: "Signature",
            placeholder: "Signature",
          },
        },
      },
      parameters: {
        title: {
          test: "Paramètres de test",
          production: "Paramètres de production",
        },
        hasConfiguration: {
          action: "Modifier",
        },
        hasNoConfiguration: {
          action: "Paramètrer",
          description: "Vous n'avez pas encore configuré ces paramètres.",
        },
        type: {
          placeholder: "Sélectionnez un service",
        },
        mailtrap: {
          username: "Identifiant",
          password: "Mot de passe",
          help: {
            title: "Aide pour Mailtrap",
            description:
              "Sélectionnez une <strong>inboxe</strong><br/>Ouvrir les paramètres<br/>Afficher les identifiants <strong>Username</strong> et <strong>Password</strong><br/>",
          },
          fields: {
            username: { placeholder: "Identifiant" },
            password: { placeholder: "Mot de passe" },
          },
        },
        sendinblue: {
          key: "Clé API",
          help: {
            title: "Aide pour Sendinblue",
            description: "Coming...",
          },
          fields: {
            key: { placeholder: "Clé API" },
          },
        },
        nodemailer: {
          host: "Hôte",
          port: "Port",
          email: "Email",
          password: "Mot de passe",
          help: {
            title: "Aide pour Nodemailer",
            description: "Coming...",
          },
          fields: {
            host: { placeholder: "Hôte" },
            port: { placeholder: "Port" },
            email: { placeholder: "Email" },
            password: { placeholder: "Mot de passe" },
          },
        },
      },
    },
    slack: {
      title: "Configuration de l'envoi des notifications Slack",
      actions: { add: "Ajouter un canal" },
      channels: { title: "Canals", empty: "Aucun canal" },
      testChannel: {
        title: "Canal de test",
        empty: "Aucun canal de test défini",
        actions: { add: "Définir" },
        form: {
          choose: "Choisir un canal",
        },
        modals: {
          add: {
            title: "Définir un canal de test",
          },
          remove: {
            title: "Supprimer le canal de test",
            description:
              "Êtes-vous sûr•e de vouloir supprimer le canal de test ?",
          },
          edit: {
            title: "Modifier le canal de test",
          },
        },
      },
      form: {
        name: {
          description: "Cette information sert uniquement à nommer les URLs.",
          label: "Nom",
          placeholder: "Nom",
        },
        url: {
          description: "Comment trouver le Webhook URL ?",
          label: "Webhook URL",
          placeholder: "Webhook URL",
        },
      },
      modals: {
        add: {
          title: "Ajouter un canal",
        },
        remove: {
          title: "Supprimer le canal {{name}}",
          description: "Êtes-vous sûr•e de vouloir supprimer ce canal ?",
        },
        edit: {
          title: "Modifier le canal {{name}}",
        },
      },
    },
  },
};

export default configuration;
