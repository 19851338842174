import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Button, Typography } from "antd";
import * as Icon from "@ant-design/icons";

import UserAvatar from "components/UserAvatar";

type Props = {
  user: AuthenticatedUser;
};

export default function User(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function logout() {
    authService.logout();
    navigate("/login");
  }

  return (
    <Fragment>
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#fff",
          color: "#000",
          display: "flex",
          padding: "0 10px",
        }}
      >
        <UserAvatar
          size="large"
          style={{ margin: ".8em .5em 0 0" }}
          user={props.user}
        />

        <div>
          <Typography.Title level={5} style={{ marginBottom: "3px" }}>
            {props.user.firstName} {props.user.lastName}
          </Typography.Title>
          <small style={{ opacity: 0.5 }}>{props.user.email}</small>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 7px",
        }}
      >
        <Button
          icon={<Icon.SettingOutlined />}
          size="small"
          style={{ marginRight: "10px" }}
        >
          <Link to="/parameters" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
            {t("layout.private.navigation.submenu.parameters")}
          </Link>
        </Button>
        <Button icon={<Icon.LogoutOutlined />} size="small" onClick={logout}>
          {t("layout.private.navigation.submenu.logout")}
        </Button>
      </div>
    </Fragment>
  );
}
