import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Alert, Button, Card, Input, Typography } from "antd";

import PublicLayout from "layouts/PublicLayout";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  async function login() {
    setError(null);
    setLoading(true);

    await authService.login(credentials).then((response) => {
      if (response.success) {
        navigate("/dashboard");
      } else {
        setError(response.message);
      }
    });

    setLoading(false);
  }

  return (
    <PublicLayout>
      <div
        style={{ alignItems: "center", display: "flex", minHeight: "100vh" }}
      >
        <Card
          bordered={false}
          style={{
            margin: "auto",
            maxWidth: "400px",
            width: "50%",
          }}
        >
          <Typography.Title level={2} style={{ textAlign: "center" }}>
            {t("pages.login.title")}
          </Typography.Title>

          {error !== null && (
            <Alert
              message={t(`pages.login.errors.${error}`)}
              style={{ marginBottom: "1em" }}
              type="error"
              showIcon
            />
          )}

          {location.search.includes("register=ok") && (
            <Alert
              message={t(`pages.login.registerOk`)}
              style={{ marginBottom: "1em" }}
              type="success"
              showIcon
            />
          )}

          <Input
            placeholder={t("pages.login.fields.email")}
            style={{ marginBottom: "1em" }}
            type="email"
            value={credentials.email}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
          />

          <Link
            style={{
              display: "block",
              marginLeft: "auto",
              width: "fit-content",
            }}
            to="/reset-password"
          >
            <small> {t("pages.login.forgotPassword")}</small>
          </Link>
          <Input
            placeholder={t("pages.login.fields.password")}
            style={{ marginBottom: "1em" }}
            type="password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
          />

          <Button
            loading={loading}
            style={{ display: "block", margin: "0 auto" }}
            type="primary"
            onClick={login}
          >
            {t("pages.login.action")}
          </Button>

          <Link to="/register">
            <Button style={{ display: "block", margin: "2em auto 0" }}>
              {t("pages.login.register")}
            </Button>
          </Link>
        </Card>
      </div>
    </PublicLayout>
  );
}
