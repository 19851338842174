import { useTranslation } from "react-i18next";

// COMPONENTS
import { Modal, Typography } from "antd";

type Props = {
  action: Action;

  onClose: () => void;
};

export default function ActionPopup(props: Props) {
  const { t } = useTranslation();

  function getVariables(): Array<string> {
    if (
      props.action.template.content !== null ||
      props.action.template.subject !== null
    ) {
      const regex = /{%-\s*([^{%}]+)\s*%}/g;
      const contentVariables =
        props.action.template.content === null
          ? null
          : props.action.template.content.match(regex);
      const subjectVariables =
        props.action.template.subject === null
          ? null
          : props.action.template.subject.match(regex);

      const list = [
        ...(contentVariables === null ? [] : contentVariables),
        ...(subjectVariables === null ? [] : subjectVariables),
      ];

      const variables = list.map((item) => {
        const variable = item.replace(regex, "$1");
        return variable;
      });

      return [...new Set(variables)];
    }
    return [];
  }

  return (
    <Modal
      centered
      footer={false}
      title={t("pages.actions.modals.action.title", {
        name: props.action.name,
      })}
      visible={true}
      onCancel={props.onClose}
    >
      <Typography.Title level={5}>
        {t("pages.actions.modals.action.variables.title")}
      </Typography.Title>

      {getVariables().length === 0 ? (
        <Typography.Paragraph>
          {t("pages.actions.modals.action.variables.empty")}
        </Typography.Paragraph>
      ) : (
        <ul>
          {getVariables().map((variable) => (
            <li>{variable}</li>
          ))}
        </ul>
      )}
    </Modal>
  );
}
