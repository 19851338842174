import parse from "html-react-parser";

type Props = {
  content: string;
};

export default function SlackPreview(props: Props) {
  function markdownToHtml() {
    // const content = props.content.split(/\*(.*)\*/);
    const content = props.content
      .replace(/<([^*]+)\|([^*]+)>/g, "<a href='$1'>$2</a>")
      .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")
      .replace(/_([^*]+)_/g, "<i>$1</i>")
      .replace(
        /~([^*]+)~/g,
        '<span style="text-decoration: line-through;">$1</span>'
      )
      .replace(
        /```([^*]+)```/g,
        '<div style="background-color: rgba(0, 0, 0, .05); border: 1px solid rgba(0, 0, 0, .1); border-radius: 5px; font-family: Courier New; padding: 3px 7px">$1</div>'
      )
      .replace(
        /`([^*]+)`/g,
        '<span style="background-color: rgba(0, 0, 0, .05); border: 1px solid rgba(0, 0, 0, .1); border-radius: 5px; color: rgba(255, 0, 0, .5); font-family: Courier New; padding: 3px 7px; white-space: initial;">$1</span>'
      );
    return content;
  }
  return <div style={{ padding: "1em" }}>{parse(markdownToHtml())}</div>;
}
