// UTILS
import { firstLetter } from "utils/texts";

// COMPONENTS
import { Avatar } from "antd";

type Props = {
  project: SimpleProject;
};

export default function CurrentProject(props: Props) {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        borderTop: "1px solid #f0f2f5",
        color: "#000",
        cursor: "pointer",
        padding: "1em 10px",
      }}
    >
      <Avatar
        size="small"
        style={{ marginRight: "10px" }}
        src={props.project.logo}
      >
        {firstLetter(props.project.name)}
      </Avatar>
      {props.project.name}
    </div>
  );
}
