import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// COMPONENTS
import { Button, Form } from "antd";

import PrivateLayout from "layouts/PrivateLayout";

import Input from "components/Input";
import projectService from "services/project";

export default function NewProject() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form, setForm] = useState({ name: "" });
  const [errors, setErrors] = useState({ name: false });
  const [loading, setLoading] = useState(false);

  async function save() {
    setLoading(true);
    setErrors({ name: false });

    const newErrors = {
      name: form.name === "",
    };

    setErrors(newErrors);

    if (!newErrors.name) {
      const body = form;

      await projectService.create(body).then((response) => {
        if (response.success) {
          navigate("/");
        }
      });
    }

    setLoading(false);
  }

  return (
    <PrivateLayout title={t("pages.newProject.title")}>
      <Form layout="vertical">
        <Input
          error={errors.name}
          errorMessage={t("pages.newProject.form.name.error")}
          label={t("pages.newProject.form.name.label")}
          placeholder={t("pages.newProject.form.name.placeholder")}
          value={form.name}
          onChange={(value) => setForm({ ...form, name: value })}
        />
      </Form>

      <Button loading={loading} type="primary" onClick={save}>
        {t("actions.save")}
      </Button>
    </PrivateLayout>
  );
}
