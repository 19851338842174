import { useTranslation } from "react-i18next";

// COMPONENTS
import { Typography } from "antd";

import Channel from "./channels/Channel";

type Props = {
  channels: Array<ConfigurationSlack>;
  testChannel: ConfigurationSlack | null;

  onEdit: (channel: ConfigurationSlack) => void;
  onRemove: (id: number) => void;
};

export default function Channels(props: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Typography.Title level={5}>
        {t("pages.configuration.tabs.slack.channels.title")}
      </Typography.Title>

      {props.channels.length === 0 ? (
        <Typography.Paragraph>
          {t("pages.configuration.tabs.slack.channels.empty")}
        </Typography.Paragraph>
      ) : (
        props.channels.map((channel) => (
          <Channel
            channel={channel}
            disabled={
              props.testChannel !== null && props.testChannel.id === channel.id
            }
            key={channel.id}
            onEdit={props.onEdit}
            onRemove={() => props.onRemove(channel.id)}
          />
        ))
      )}
    </div>
  );
}
