import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Menu } from "antd";

import UserAvatar from "components/UserAvatar";

import Projects from "./navigation/Projects";
import User from "./navigation/User";

type Props = {
  user: AuthenticatedUser;
};

export default function Navigation(props: Props) {
  const { t } = useTranslation();

  const items = [
    {
      name: t("layout.private.navigation.dashboard"),
      path: "/dashboard",
    },
    {
      name: t("layout.private.navigation.templates"),
      path: "/templates",
    },
    {
      name: t("layout.private.navigation.history"),
      path: "/history",
    },
    ...(authService.hasRank(["admin"])
      ? [
          {
            name: t("layout.private.navigation.actions"),
            path: "/actions",
          },
          {
            name: t("layout.private.navigation.configuration"),
            path: "/configuration",
          },
        ]
      : []),
  ];

  return (
    <Menu mode="horizontal" selectedKeys={[]} theme="dark">
      {items.map((item, key) => (
        <Menu.Item key={key.toString()}>
          <Link to={item.path}>{item.name}</Link>
        </Menu.Item>
      ))}

      <Menu.SubMenu
        key="SubMenu"
        style={{ marginLeft: "auto" }}
        title={<UserAvatar user={props.user} />}
      >
        <div>
          <User user={props.user} />

          <Projects user={props.user} />
        </div>
      </Menu.SubMenu>
    </Menu>
  );
}
