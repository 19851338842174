import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// SERVICES
import authService from "services/auth";
import projectService from "services/project";

// COMPONENTS
import { Button, message } from "antd";

import ReactivatePopup from "./actions/ReactivatePopup";

type Props = {
  user: ProjectUser;

  onUpdate: (users: ProjectUser) => void;
};

export default function Actions(props: Props) {
  const { t } = useTranslation();

  const currentUser = authService.getAuthenticatedUser();
  const [showPopup, setShowPopup] = useState(false);
  const [invitationLoading, setInvitationLoading] = useState(false);

  async function sendInvitationAgain() {
    setInvitationLoading(true);

    await projectService.sendInvitationAgain(props.user.id).then((response) => {
      if (response.success) {
        message.info(
          t("pages.parameters.tabs.project.users.sendInvitationAgainMessage")
        );
      }
    });

    setInvitationLoading(false);
  }

  function reactivate(user: ProjectUser) {
    setShowPopup(false);
    props.onUpdate(user);
  }

  return (
    <Fragment>
      {(() => {
        switch (props.user.status) {
          case "accepted_invitation":
          case "creator":
            return (
              <Button type="link">
                <Link to={`/parameters/project-users/${props.user.id}`}>
                  {t(
                    "pages.parameters.tabs.project.users.table.actions.cases.seeDetails"
                  )}
                </Link>
              </Button>
            );
          case "desactivated":
            return (
              <Button type="link" onClick={() => setShowPopup(true)}>
                {t(
                  "pages.parameters.tabs.project.users.table.actions.cases.reactivate"
                )}
              </Button>
            );
          case "pending_invitation":
            return (
              <Button
                loading={invitationLoading}
                type="link"
                onClick={sendInvitationAgain}
              >
                {t(
                  "pages.parameters.tabs.project.users.table.actions.cases.sendInvitationAgain"
                )}
              </Button>
            );
        }
      })()}

      {currentUser !== null && (
        <ReactivatePopup
          currentUser={currentUser}
          isVisible={showPopup}
          user={props.user}
          onClose={() => setShowPopup(false)}
          onReactivate={reactivate}
        />
      )}
    </Fragment>
  );
}
