import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import projectService from "services/project";

// COMPONENTS
import { Col, Row, Typography } from "antd";

import FileEdit from "components/FileEdit";
import InputEdit from "components/InputEdit";
import Spinner from "components/Spinner";

export default function Customization() {
  const { t } = useTranslation();

  const [parameters, setParameters] = useState<ProjectParameter | undefined>(
    undefined
  );
  const [fetching, setFetching] = useState(true);

  async function fetchParameters() {
    await projectService.getParameters().then((response) => {
      if (response.success) {
        setParameters(response.value);
      }
    });

    setFetching(false);
  }

  useEffect(() => {
    fetchParameters();
  }, []);

  async function save(
    body:
      | { name: string | null }
      | { sendingEmail: string | null }
      | { signature: string | null }
  ) {
    await projectService.updateParameters(body).then((response) => {
      if (response.success) {
        setParameters(response.value);
      }
    });
  }

  async function saveFile(formData: FormData) {
    await projectService.updateParameterLogo(formData).then((response) => {
      if (response.success) {
        setParameters(response.value);
      }
    });
  }

  return (
    <div>
      <Typography.Title level={4}>
        {t(`pages.configuration.tabs.email.customization.title`)}
      </Typography.Title>

      <Spinner spinning={fetching} data={parameters}>
        {parameters && (
          <Row gutter={[16, 16]}>
            {/* Name */}
            <Col xs={24} md={12}>
              <InputEdit
                label={t(
                  `pages.configuration.tabs.email.customization.fields.name.label`
                )}
                placeholder={t(
                  `pages.configuration.tabs.email.customization.fields.name.placeholder`
                )}
                value={parameters.name}
                onSave={(value) =>
                  save({
                    name: value,
                  })
                }
              />
            </Col>

            {/* Logo */}
            <Col xs={24} md={12}>
              <FileEdit
                file={parameters.logo}
                label={t(
                  `pages.configuration.tabs.email.customization.fields.logo.label`
                )}
                placeholder={t(
                  `pages.configuration.tabs.email.customization.fields.logo.placeholder`
                )}
                width="100px"
                onSave={saveFile}
              />
            </Col>

            {/* Sending email */}
            <Col xs={24} md={12}>
              <InputEdit
                label={t(
                  `pages.configuration.tabs.email.customization.fields.sendingEmail.label`
                )}
                placeholder={t(
                  `pages.configuration.tabs.email.customization.fields.sendingEmail.placeholder`
                )}
                value={parameters.sendingEmail}
                onSave={(value) =>
                  save({
                    sendingEmail: value,
                  })
                }
              />
            </Col>

            {/* Signature */}
            <Col xs={24} md={12}>
              <InputEdit
                label={t(
                  `pages.configuration.tabs.email.customization.fields.signature.label`
                )}
                placeholder={t(
                  `pages.configuration.tabs.email.customization.fields.signature.placeholder`
                )}
                value={parameters.signature}
                type="textarea"
                onSave={(value) =>
                  save({
                    signature: value,
                  })
                }
              />
            </Col>
          </Row>
        )}
      </Spinner>
    </div>
  );
}
