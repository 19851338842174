import { Fragment, useState } from "react";

// COMPONENTS
import { Button, Space } from "antd";
import * as Icon from "@ant-design/icons";

import DetailsPopup from "./actions/DetailsPopup";

type Props = {
  history: DataHistory;

  onUpdate: () => void;
};

export default function Actions(props: Props) {
  const [showDetails, setShowDetails] = useState(false);

  function redo() {
    setShowDetails(false);
    props.onUpdate();
  }

  return (
    <Fragment>
      <Button
        icon={<Icon.EyeOutlined />}
        size="small"
        type="primary"
        onClick={() => setShowDetails(true)}
      />

      <DetailsPopup
        isVisible={showDetails}
        history={props.history}
        onClose={() => setShowDetails(false)}
        onRedo={redo}
      />
    </Fragment>
  );
}
