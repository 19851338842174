const templates = {
  title: "Templates",
  actions: { add: "Créer une template" },
  modal: {
    title: "Créer une template",
    fields: {
      channel: { label: "Type", placeholder: "Choisir un type" },
      name: { label: "Nom", placeholder: "Nom" },
      description: {
        label: "Description",
        placeholder: "Description",
      },
    },
  },
  template: {
    actions: { preview: "Prévisualiser" },
    fields: {
      content: { placeholder: "Contenu" },
      subject: { placeholder: "Objet" },
    },
  },
};

export default templates;
