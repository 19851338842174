import axios from "axios";

import { Result } from "../utils/Result";

import { apiUrl } from "index";

import { getHeaders } from "./auth";

async function create(body: {
  channel: Channel;
  name: string;
  description: string | null;
}): Promise<Result<Template>> {
  return axios
    .post(`${apiUrl}/templates`, body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function getAll(): Promise<Result<Array<Template>>> {
  return axios
    .get(`${apiUrl}/templates`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function update(params: {
  id: number;
  body: { content: string; subject?: string };
}): Promise<Result<Template>> {
  return axios
    .put(`${apiUrl}/templates/${params.id}`, params.body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

const templateService = { create, getAll, update };
export default templateService;
