import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Layout, Menu } from "antd";
import * as Icon from "@ant-design/icons";

import AccountInformations from "./account/Informations";
import Email from "./account/Email";
import ProjectInformations from "./project/Informations";
import Security from "./account/Security";
import Users from "./project/Users";

type Props = {
  user: AuthenticatedUser;
};

export default function Parameters(props: Props) {
  const location = useLocation();
  const { t } = useTranslation();

  const activeKey = (() => {
    switch (location.pathname) {
      case "/parameters/project-informations":
        return "00";
      case "/parameters/project-users":
        return "01";
      case "/parameters/project-api":
        return "02";
      case "/parameters/account-informations":
        return "10";
      case "/parameters/account-email":
        return "11";
      case "/parameters/account-security":
        return "12";
      default: {
        if (location.pathname.includes("/parameters/project-users")) {
          return "01";
        } else {
          return "00";
        }
      }
    }
  })();

  const groups: Array<{
    icon: React.ReactChild | null;
    title: string;
    items: Array<{
      content: React.ReactChild;
      icon: any | null;
      path: string;
      title: string;
      disabled: boolean;
    }>;
  }> = [
    {
      icon: null,
      title: t("pages.parameters.navigation.project.title", {
        name: props.user.project.name,
      }),
      items: [
        {
          content: <ProjectInformations />,
          icon: <Icon.InfoCircleOutlined />,
          disabled: false,
          path: "/parameters/project-informations",
          title: t("pages.parameters.navigation.project.items.informations"),
        },
        {
          content: <Users />,
          icon: <Icon.TeamOutlined />,
          disabled: false,
          path: "/parameters/project-users",
          title: t("pages.parameters.navigation.project.items.users"),
        },
        ...(authService.hasRank(["admin"])
          ? [
              {
                content: <div />,
                icon: <Icon.ApiOutlined />,
                disabled: false,
                path: "/parameters/project-api",
                title: t("pages.parameters.navigation.project.items.api"),
              },
            ]
          : []),
      ],
    },
    {
      icon: null,
      title: t("pages.parameters.navigation.account.title"),
      items: [
        {
          content: <AccountInformations />,
          icon: <Icon.InfoCircleOutlined />,
          disabled: false,
          path: "/parameters/account-informations",
          title: t("pages.parameters.navigation.account.items.informations"),
        },
        {
          content: <Email />,
          icon: <Icon.MailOutlined />,
          disabled: false,
          path: "/parameters/account-email",
          title: t("pages.parameters.navigation.account.items.email"),
        },
        {
          content: <Security />,
          icon: <Icon.LockOutlined />,
          disabled: true,
          path: "/parameters/account-security",
          title: t("pages.parameters.navigation.account.items.security"),
        },
      ],
    },
  ];

  return (
    <Layout.Sider style={{ backgroundColor: "#f0f2f5" }}>
      <Menu
        selectedKeys={[activeKey]}
        mode="vertical"
        style={{
          backgroundColor: "#fff",
          border: "none",
          borderRadius: "10px",
          padding: "10px 0",
        }}
      >
        {groups.map((group, keyGroup) => (
          <Menu.ItemGroup key={keyGroup.toString()} title={group.title}>
            {group.items.map((item, keyItem) => (
              <Menu.Item
                key={`${keyGroup}${keyItem}`}
                icon={item.icon}
                disabled={item.disabled}
                style={{ margin: 0 }}
              >
                <Link to={item.path}>{item.title}</Link>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        ))}
      </Menu>
    </Layout.Sider>
  );
}
