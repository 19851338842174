import { useNavigate } from "react-router-dom";

// SERVICES
import authService from "../services/auth";

// COMPONENTS
import { Layout } from "antd";

type Props = {
  children: React.ReactNode;
};

export default function PublicLayout(props: Props) {
  const navigate = useNavigate();

  const user = authService.getAuthenticatedUser();
  if (user !== null) {
    navigate("/dashboard");
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Content>{props.children}</Layout.Content>
    </Layout>
  );
}
