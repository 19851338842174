import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import templateService from "services/template";

// COMPONENTS
import { Editor } from "@tinymce/tinymce-react";
import { Button, Col, Input, Layout, Modal, Row, Typography } from "antd";
import * as Icon from "@ant-design/icons";

import Preview from "./template/Preview";

type Props = {
  template: Template;

  onUpdate: (template: Template) => void;
};

export default function Template(props: Props) {
  const { t } = useTranslation();

  const editorRef = useRef<any>(null);
  const [form, setForm] = useState({
    content: props.template.content || "",
    subject: props.template.subject || "",
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setForm({
      content: props.template.content || "",
      subject: props.template.subject || "",
    });
  }, [props.template]);

  async function save() {
    setLoading(true);

    const body = {
      content: form.content,
      ...(() => {
        switch (props.template.channel) {
          case "email":
            return { subject: form.subject };
          case "slack":
          case "sms":
            return {};
        }
      })(),
    };

    await templateService
      .update({ id: props.template.id, body })
      .then((response) => {
        if (response.success) {
          props.onUpdate(response.value);
        }
      });

    setLoading(false);
  }

  return (
    <Fragment>
      <Layout.Content style={{ padding: "0 1em 0 2em" }}>
        <Row align="middle" style={{ marginBottom: "2em" }}>
          <Col span={20}>
            <Typography.Title level={3} style={{ margin: 0 }}>
              {props.template.name}
            </Typography.Title>
            {props.template.description !== null && (
              <Typography.Text style={{ opacity: 0.5, whiteSpace: "pre-line" }}>
                {props.template.description}
              </Typography.Text>
            )}
          </Col>

          <Col span={4}>
            <Button
              icon={<Icon.EyeOutlined />}
              style={{ display: "block", marginLeft: "auto" }}
              type="primary"
              onClick={() => setShowModal(true)}
            >
              {t("pages.templates.template.actions.preview")}
            </Button>
          </Col>
        </Row>

        {props.template.channel === "email" && (
          <Input
            placeholder={t(
              "pages.templates.template.fields.subject.placeholder"
            )}
            style={{ marginBottom: "1em" }}
            value={form.subject}
            onChange={(e) => setForm({ ...form, subject: e.target.value })}
          />
        )}

        {(() => {
          switch (props.template.channel) {
            case "email":
              return (
                // @ts-ignore
                <Editor
                  apiKey="498ctyyixkrh0hryjsh7qo0bhbxd9d7jxqvjh4wsaepg1uzz"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  value={props.template.content || ""}
                  init={{
                    menubar: false,
                    plugins: [
                      "advlist autolink autoresize lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "link removeformat | help",
                    content_style:
                      "body { font-family: Helvetica,Arial,sans-serif; font-size: 14px; }",
                  }}
                  onEditorChange={(value) =>
                    setForm({ ...form, content: value })
                  }
                />
              );
            case "slack":
            case "sms":
              return (
                <Input.TextArea
                  autoSize
                  placeholder={t(
                    "pages.templates.template.fields.content.placeholder"
                  )}
                  value={form.content}
                  onChange={(e) =>
                    setForm({ ...form, content: e.target.value })
                  }
                />
              );
          }
        })()}

        <Button
          loading={loading}
          style={{ display: "block", margin: "1em 0 0 auto" }}
          type="primary"
          onClick={save}
        >
          {t("actions.save")}
        </Button>
      </Layout.Content>

      <Modal
        centered={true}
        visible={showModal}
        footer={false}
        width="100%"
        onCancel={() => setShowModal(false)}
      >
        <Preview channel={props.template.channel} content={form.content} />
      </Modal>
    </Fragment>
  );
}
