// COMPONENTS
import EmailPreview from "./preview/EmailPreview";
import SlackPreview from "./preview/SlackPreview";
import SmsPreview from "./preview/SmsPreview";

type Props = {
  channel: Template["channel"];
  content: string;
};

export default function Preview(props: Props) {
  function replaceVariables() {
    const content = props.content.replace(
      /{%-\s*([^{%}]+)\s*%}/g,
      '<span style="color: rgba(255, 0, 0, .5); font-style: italic;">$1</span>'
    );

    return content;
  }

  return (
    <div
      style={{
        backgroundColor: "#f0f2f5",
        border: "1px solid #d9d9d9",
        borderRadius: "10px",
        marginTop: "2em",
        overflow: "hidden",
        whiteSpace: "pre-line",
      }}
    >
      {(() => {
        switch (props.channel) {
          case "email":
            return <EmailPreview content={replaceVariables()} />;
          case "slack":
            return <SlackPreview content={replaceVariables()} />;
          case "sms":
            return <SmsPreview content={replaceVariables()} />;
        }
      })()}
    </div>
  );
}
