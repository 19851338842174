import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// COMPONENTS
import * as Icon from "@ant-design/icons";

export default function AddProject() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: "#f0f2f5",
        padding: "1em 10px",
      }}
    >
      <Link style={{ color: "rgba(0, 0, 0, 0.5)" }} to="/new-project">
        <Icon.PlusOutlined />{" "}
        {t("layout.private.navigation.submenu.addProject")}
      </Link>
    </div>
  );
}
