import { useState } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button, Form } from "antd";
import * as Icon from "@ant-design/icons";

import Input from "components/Input";

type Props = {
  form: { name: string; url: string };

  onChange: (form: { name: string; url: string }) => void;
};

export default function ChannelForm(props: Props) {
  const { t } = useTranslation();

  const [showHelp, setShowHelp] = useState(false);

  return (
    <Form layout="vertical">
      <Input
        label={t("pages.configuration.tabs.slack.form.name.label")}
        placeholder={t("pages.configuration.tabs.slack.form.name.placeholder")}
        style={{ margin: 0 }}
        value={props.form.name}
        onChange={(value) => props.onChange({ ...props.form, name: value })}
      />
      <small>{t("pages.configuration.tabs.slack.form.name.description")}</small>

      <br />
      <br />

      <Input
        label={t("pages.configuration.tabs.slack.form.url.label")}
        placeholder={t("pages.configuration.tabs.slack.form.url.placeholder")}
        style={{ margin: 0 }}
        value={props.form.url}
        onChange={(value) => props.onChange({ ...props.form, url: value })}
      />
      <small
        style={{ cursor: "pointer" }}
        onClick={() => setShowHelp(!showHelp)}
      >
        {t("pages.configuration.tabs.slack.form.url.description")}{" "}
        {!showHelp ? <Icon.CaretDownOutlined /> : <Icon.CaretUpOutlined />}
      </small>

      {/* TODO : improve that */}
      {showHelp && (
        <div>
          <a href="https://api.slack.com/apps">Apps</a>
          <p>Features : Incoming Webhooks</p>
          <p>Add New Webhook to Workspace</p>
          <p>Sélectionner un chanal</p>
          <p>Copier le Webhook URL</p>
        </div>
      )}
    </Form>
  );
}
