import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import historyService from "services/history";

// MODELS
import { ColumnType } from "antd/lib/table";

// UTILS
import { DD_MM_YYYY_HH_MM_SS } from "utils/dates";

// COMPONENTS
import { Pagination, Spin, Table, Tag } from "antd";
import * as Icon from "@ant-design/icons";

import Actions from "./table/Actions";

type Props = {
  type: "production" | "test";
};

export default function DataTable(props: Props) {
  const { t } = useTranslation();

  const [fetching, setFetching] = useState(true);
  const [history, setHistory] = useState<Array<DataHistory>>([]);
  const [pagination, setPagination] = useState({
    number: 10,
    page: 0,
    total: 0,
  });

  async function fetchHistory(page: number, number: number) {
    setFetching(true);

    await historyService
      .getAll({ type: props.type, page, number })
      .then((response) => {
        if (response.success) {
          setHistory(response.value.data);
          setPagination(response.value.pagination);
        }
      });

    setFetching(false);
  }

  useEffect(() => {
    fetchHistory(pagination.page, pagination.number);
  }, []);

  const statusTag = (status: DataHistory["status"]) => (
    <Tag color={t(`pages.history.status.${status}.color`)}>
      {t(`pages.history.status.${status}.label`)}
    </Tag>
  );

  function changePage(page: number, size: number) {
    const newPage = page - 1;
    setPagination({ ...pagination, page: newPage, number: size });
    fetchHistory(newPage, size);
  }

  const columns: Array<ColumnType<DataHistory>> = [
    {
      align: "center" as const,
      dataIndex: "id",
      key: "key",
      title: t("pages.history.table.key"),
    },
    {
      align: "center" as const,
      dataIndex: "channel",
      key: "channel",
      title: t("pages.history.table.channel"),
      render: (channel: Channel) => {
        switch (channel) {
          case "email":
            return <Icon.MailOutlined />;
          case "slack":
            return <Icon.SlackOutlined />;
          case "sms":
            return <Icon.MobileOutlined />;
        }
      },
    },
    {
      align: "center" as const,
      dataIndex: "recipient",
      key: "recipient",
      title: t("pages.history.table.recipient"),
    },
    {
      align: "center" as const,
      dataIndex: "subject",
      key: "subject",
      title: t("pages.history.table.subject"),
      render: (subject: DataHistory) => (subject === null ? {} : subject),
    },
    {
      align: "center" as const,
      dataIndex: "status",
      key: "status",
      title: t("pages.history.table.status"),
      render: (status: DataHistory["status"]) => statusTag(status),
    },
    {
      align: "center" as const,
      dataIndex: "createdAt",
      key: "date",
      title: t("pages.history.table.date"),
      render: (date: DataHistory["createdAt"]) => DD_MM_YYYY_HH_MM_SS(date),
    },
    {
      align: "center" as const,
      key: "actions",
      title: t("pages.history.table.actions"),
      render: (record: DataHistory) => (
        <Actions
          history={record}
          onUpdate={() => fetchHistory(pagination.page, pagination.number)}
        />
      ),
    },
  ];
  return (
    <div>
      {/* TODO : filters */}
      <Spin spinning={fetching}>
        <Table
          columns={columns}
          dataSource={history}
          pagination={false}
          size="small"
        />
      </Spin>
      <Pagination
        current={pagination.page + 1}
        pageSize={pagination.number}
        style={{
          display: "block",
          marginTop: "1em",
          marginLeft: "auto",
          width: "fit-content",
        }}
        total={pagination.total}
        onChange={changePage}
      />
    </div>
  );
}
