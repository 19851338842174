const register = {
  action: "S'inscrire",
  back: "Retour à la page de connexion",
  fields: {
    project: {
      title: "Votre projet",
      name: {
        label: "Nom",
        placeholder: "Nom du projet",
        error: "Merci de renseigner un nom pour le projet",
      },
    },
    user: {
      title: "Vous",
      firstName: {
        label: "Prénom",
        placeholder: "Prénom",
        error: "Merci de renseigner un prénom",
      },
      lastName: {
        label: "Nom",
        placeholder: "Nom",
        error: "Merci de renseigner un nom",
      },
      email: {
        label: "Email",
        placeholder: "Email",
        error: "Merci de renseigner une adresse email",
      },
      password: {
        label: "Mot de passe",
        placeholder: "Mot de passe",
        error: "Le mot de passe doit contenir au moins 8 caractères",
      },
      confirmationPassword: {
        label: "Confirmation du mot de passe",
        placeholder: "Confirmation du mot de passe",
        error: "La confirmation doit être identique au mot de passe",
      },
    },
  },
  errors: {
    "Internal error": "Une erreur est survenue",
    emailUsed: "L'adresse email est déjà utilisée",
  },
  title: "Inscription",
};

export default register;
