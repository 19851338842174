const newProject = {
  title: "Créer un projet",
  form: {
    name: {
      label: "Nom",
      placeholder: "Nom du projet",
      error: "Merci de renseigner un nom",
    },
  },
};

export default newProject;
