import parse from "html-react-parser";

type Props = {
  content: string;
};

export default function SmsPreview(props: Props) {
  return (
    <div
      style={{
        padding: "1em",
      }}
    >
      {parse(props.content)}
    </div>
  );
}
