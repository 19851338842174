import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Avatar, Button, Typography, Upload } from "antd";
import * as Icon from "@ant-design/icons";

type Props = {
  file: string | null;
  height?: string;
  label?: string;
  placeholder?: string;
  type?: "avatar";
  width?: string;

  onSave: (formData: FormData) => void;
};

export default function FileEdit(props: Props) {
  const { t } = useTranslation();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<Array<any>>([]);

  async function save() {
    setLoading(true);

    let formData = new FormData();
    formData.append("file", files[0]);

    props.onSave(formData);
  }

  useEffect(() => {
    setLoading(false);
    setEditing(false);
    setFiles([]);
  }, [props.file]);

  return (
    <div>
      <label style={{ fontWeight: 600 }}>{props.label}</label>

      {!editing ? (
        <div>
          {props.file === null ? (
            <Typography.Paragraph style={{ margin: 0 }}>
              {t("form.undefined")}
            </Typography.Paragraph>
          ) : (
            <div>
              {props.type === "avatar" ? (
                <Avatar
                  src={props.file}
                  style={{ height: props.height, width: props.width }}
                />
              ) : (
                <img
                  alt={props.label}
                  src={props.file}
                  height={props.height}
                  width={props.width}
                />
              )}
            </div>
          )}
          <Button
            icon={<Icon.EditOutlined />}
            type="text"
            onClick={() => setEditing(true)}
          />
        </div>
      ) : (
        <div>
          <Upload
            beforeUpload={(file) => setFiles([file])}
            fileList={files}
            onRemove={() => setFiles([])}
          >
            <Button icon={<Icon.UploadOutlined />}>
              {props.placeholder || t(`actions.chooseImage`)}
            </Button>
          </Upload>

          <div style={{ marginTop: ".5em" }}>
            <Button
              loading={loading}
              style={{ marginRight: "1em" }}
              type="primary"
              onClick={save}
            >
              {t("actions.save")}
            </Button>
            <Button
              onClick={() => {
                setEditing(false);
                setFiles([]);
              }}
            >
              {t("actions.cancel")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
