import { useNavigate } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Layout, Typography } from "antd";

import Navigation from "./privateLayout/Navigation";

type Props = {
  children: React.ReactNode;
  title: string;
};

export default function PrivateLayout(props: Props) {
  const navigate = useNavigate();

  const user = authService.getAuthenticatedUser();
  if (user === null) {
    navigate("/login");
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Header>
        {user !== null && <Navigation user={user} />}
      </Layout.Header>

      <Layout.Content style={{ padding: "20px 50px" }}>
        <Typography.Title level={2}>{props.title}</Typography.Title>
        {props.children}
      </Layout.Content>

      <Layout.Footer style={{ textAlign: "center" }}>2022</Layout.Footer>
    </Layout>
  );
}
