import { useTranslation } from "react-i18next";

// COMPONENTS
import { Form, Input } from "antd";

type Props = {
  error?: boolean;
  errorMessage?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  prefix?: React.ReactChild;
  style?: React.CSSProperties;
  suffix?: React.ReactChild;
  type?: "email" | "password" | "text" | "textarea";
  value: string;
  onChange: (value: string) => void;
};

export default function MyInput(props: Props) {
  const { t } = useTranslation();

  return (
    <Form.Item
      hasFeedback
      help={
        props.error || false
          ? props.errorMessage || t("form.defaultErrorMessage")
          : undefined
      }
      label={props.label}
      style={props.style}
      validateStatus={props.error || false ? "error" : undefined}
    >
      {props.type === "textarea" ? (
        <Input.TextArea
          autoSize
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ) : (
        <Input
          name={props.name || ""}
          prefix={props.prefix}
          placeholder={props.placeholder}
          suffix={props.suffix}
          type={props.type || "text"}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      )}
    </Form.Item>
  );
}
