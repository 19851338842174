import { useTranslation } from "react-i18next";

// COMPONENTS
import { Form } from "antd";

import Input from "components/Input";
import Select from "components/Select";

type Props = {
  form: {
    name: string;
    channel: Channel;
    templateId: number;
  };
  templates: Array<Template>;

  onChange: (form: {
    name: string;
    channel: Channel;
    templateId: number;
  }) => void;
};

export default function ActionForm(props: Props) {
  const { t } = useTranslation();

  return (
    <Form layout="vertical">
      <Input
        label={t("pages.actions.modals.add.fields.name.label")}
        placeholder={t("pages.actions.modals.add.fields.name.placeholder")}
        value={props.form.name}
        onChange={(value) => props.onChange({ ...props.form, name: value })}
      />

      <label style={{ display: "block", marginBottom: "8px" }}>
        {t("pages.actions.modals.add.fields.channel.label")}
      </label>
      <Select
        disabled={props.templates.length === 0}
        isMultiple={false}
        options={[
          {
            label: t("channels.email").toString(),
            value: "email",
          },
          {
            label: t("channels.slack").toString(),
            value: "slack",
          },
          {
            label: t("channels.sms").toString(),
            value: "sms",
          },
        ]}
        placeholder={t("pages.actions.modals.add.fields.channel.placeholder")}
        value={props.form.channel}
        width="100%"
        onChange={(value) =>
          props.onChange({
            ...props.form,
            channel: value,
            templateId: props.templates.filter(
              (item) => item.channel === value
            )[0].id,
          })
        }
      />

      <br />
      <br />

      <label style={{ display: "block", marginBottom: "8px" }}>
        {t("pages.actions.modals.add.fields.template.label")}
      </label>
      <Select
        isMultiple={false}
        options={props.templates
          .filter((item) => item.channel === props.form.channel)
          .map((template) => ({
            label: template.name,
            value: template.id.toString(),
          }))}
        placeholder={t("pages.actions.modals.add.fields.template.placeholder")}
        value={props.form.templateId.toString()}
        width="100%"
        onChange={(value) =>
          props.onChange({ ...props.form, templateId: parseInt(value) })
        }
      />
    </Form>
  );
}
