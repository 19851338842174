import { useTranslation } from "react-i18next";

// COMPONENTS
import { Form, Modal } from "antd";

import Input from "components/Input";

type MailtrapCredentials = { user: string; pass: string };

type Props = {
  credentials: MailtrapCredentials;
  showHelp: boolean;

  onChange: (credentials: MailtrapCredentials) => void;
  onCloseHelp: () => void;
};

export default function Mailtrap(props: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Form layout="vertical">
        <div style={{ display: "flex" }}>
          <Input
            placeholder={t(
              "pages.configuration.tabs.email.parameters.mailtrap.fields.username.placeholder"
            )}
            style={{ margin: "0 1em 0 0", width: "100%" }}
            value={props.credentials.user}
            onChange={(value) =>
              props.onChange({ ...props.credentials, user: value })
            }
          />

          <Input
            placeholder={t(
              "pages.configuration.tabs.email.parameters.mailtrap.fields.password.placeholder"
            )}
            style={{ margin: 0, width: "100%" }}
            value={props.credentials.pass}
            onChange={(value) =>
              props.onChange({ ...props.credentials, pass: value })
            }
          />
        </div>
      </Form>

      {/* Help */}
      <Modal
        centered
        footer={false}
        title={t(
          "pages.configuration.tabs.email.parameters.mailtrap.help.title"
        )}
        visible={props.showHelp}
        onCancel={props.onCloseHelp}
      >
        <a href="https://mailtrap.io/" target="_blank" rel="noreferrer">
          Mailtrap
        </a>

        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "pages.configuration.tabs.email.parameters.mailtrap.help.description"
            ),
          }}
        />
      </Modal>
    </div>
  );
}
