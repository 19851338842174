import { useTranslation } from "react-i18next";

// COMPONENTS
import { Tag, Typography } from "antd";
import UserAvatar from "components/UserAvatar";

type Props = {
  user: ProjectUser;
};

export default function User(props: Props) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
      }}
    >
      <UserAvatar user={props.user} style={{ marginRight: "10px" }} />

      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography.Text style={{ lineHeight: 1, marginTop: "2px" }}>
          {props.user.firstName === null && props.user.lastName === null
            ? props.user.email
            : `${props.user.firstName} ${props.user.lastName}`}
        </Typography.Text>

        {!(props.user.firstName === null && props.user.lastName === null) && (
          <small style={{ opacity: 0.5 }}>{props.user.email}</small>
        )}
      </div>

      {["desactivated", "pending_invitation"].includes(props.user.status) && (
        <Tag style={{ marginLeft: "1em" }}>
          {t(
            `pages.parameters.tabs.project.users.table.status.${props.user.status}`
          )}
        </Tag>
      )}
    </div>
  );
}
