import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import projectService from "services/project";

// COMPONENTS
import { Button, Form, Modal } from "antd";

import Input from "components/Input";
import Select from "components/Select";

type Props = {
  isVisible: boolean;

  onClose: () => void;
  onInvite: (user: ProjectUser) => void;
};

const initialFormValue: { email: string; rank: Rank } = {
  email: "",
  rank: "writer",
};

export default function InvitePopup(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState<typeof initialFormValue>(initialFormValue);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: false });

  async function invite() {
    setLoading(true);
    setErrors({ email: false });

    const formErrors = {
      email: form.email === "",
    };
    setErrors(formErrors);

    if (!formErrors.email) {
      const body = {
        email: form.email,
        rank: form.rank,
      };

      await projectService.invite(body).then((response) => {
        if (response.success) {
          props.onInvite(response.value);
          setForm(initialFormValue);
        }
      });
    }

    setLoading(false);
  }

  return (
    <Modal
      footer={[
        <Button key="back" onClick={props.onClose}>
          {t("actions.cancel")}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={invite}>
          {t("pages.parameters.tabs.project.users.actions.invite")}
        </Button>,
      ]}
      visible={props.isVisible}
      title={t("pages.parameters.tabs.project.users.modals.invite.title")}
      onCancel={props.onClose}
    >
      <Form layout="vertical">
        <Input
          error={errors.email}
          errorMessage={t(
            "pages.parameters.tabs.project.users.modals.invite.fields.email.error"
          )}
          label={t(
            "pages.parameters.tabs.project.users.modals.invite.fields.email.label"
          )}
          placeholder={t(
            "pages.parameters.tabs.account.email.edit.field.placeholder"
          )}
          style={{ marginBottom: "1em" }}
          type="email"
          value={form.email}
          onChange={(value) => setForm({ ...form, email: value })}
        />

        <Form.Item
          label={t(
            "pages.parameters.tabs.project.users.modals.invite.fields.rank.label"
          )}
        >
          <Select
            isMultiple={false}
            options={[
              { label: t("ranks.writer.label").toString(), value: "writer" },
              { label: t("ranks.admin.label").toString(), value: "admin" },
            ]}
            width="100%"
            value={form.rank}
            onChange={(value) => setForm({ ...form, rank: value })}
          />
          <small>{t(`ranks.${form.rank}.description`)}</small>
        </Form.Item>
      </Form>
    </Modal>
  );
}
