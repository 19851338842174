import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// SERVICES
import projectService from "services/project";

// COMPONENTS
import { Typography } from "antd";

import Spinner from "components/Spinner";

import InvitationOk from "./invitation/InvitationOk";

export default function Invitiation() {
  const { t } = useTranslation();

  const { token } = useParams<{ token: string }>();

  const [fetching, setFetching] = useState(true);
  const [isTokenOk, setIsTokenOk] = useState(false);
  const [project, setProject] = useState<{ id: number; name: string } | null>(
    null
  );
  const [user, setUser] = useState<{
    id: number;
    firstName: string | null;
    lastName: string | null;
    email: string;
  } | null>(null);

  async function checkToken() {
    await projectService
      .checkInvitationToken(token || "")
      .then(async (response) => {
        if (response.success === true) {
          const { user, project } = response.value;
          setIsTokenOk(true);
          setUser(user);
          setProject(project);

          if (user.firstName !== null && user.lastName !== null) {
            await projectService.acceptInvitation({
              token: token || "",
              body: {},
            });
          }
        } else {
          setIsTokenOk(false);
        }
      });

    setFetching(false);
  }

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        margin: "0 auto",
        padding: "2em",
        width: "50%",
      }}
    >
      <Spinner spinning={fetching} data={user}>
        <Typography.Title level={3}>
          {t("pages.invitation.title")}
        </Typography.Title>

        {!isTokenOk ? (
          <Typography.Paragraph>
            {t("pages.invitation.expiredToken")}
          </Typography.Paragraph>
        ) : (
          project !== null &&
          user !== null && (
            <InvitationOk
              project={project}
              token={token || ""}
              user={user}
              onAccept={(value) => setUser(value)}
            />
          )
        )}
      </Spinner>
    </div>
  );
}
