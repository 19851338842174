import axios from "axios";

import { Result } from "../utils/Result";

import { apiUrl } from "index";

import { getHeaders } from "./auth";

async function create(body: {
  name: string;
  url: string;
  type: "production";
}): Promise<Result<ConfigurationSlack>> {
  return axios
    .post(`${apiUrl}/configuration-slack`, body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: "" };
    });
}

async function getAll(): Promise<Result<Array<ConfigurationSlack>>> {
  return axios
    .get(`${apiUrl}/configuration-slack`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function remove(id: number): Promise<Result<void>> {
  return axios
    .delete(`${apiUrl}/configuration-slack/${id}`, getHeaders())
    .then(() => {
      return { success: true as const, value: undefined };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function update(params: {
  id: number;
  body: { name?: string; url?: string; type?: "test" | "production" };
}): Promise<Result<ConfigurationSlack>> {
  return axios
    .put(
      `${apiUrl}/configuration-slack/${params.id}`,
      params.body,
      getHeaders()
    )
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: "" };
    });
}

const configurationSlackService = { create, getAll, remove, update };
export default configurationSlackService;
