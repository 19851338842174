import React from "react";

// COMPONENTS
import { Breadcrumb, Card, Layout, Typography } from "antd";
import * as Icon from "@ant-design/icons";

type Props = {
  children: React.ReactNode;
  pages: Array<{ name: string; path: string }>;
  title: string;
};

export default function Parameters(props: Props) {
  return (
    <Layout.Content
      style={{
        paddingLeft: "2em",
      }}
    >
      <div>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {props.title}
        </Typography.Title>

        <Breadcrumb separator=">" style={{ margin: "3px 0 7px" }}>
          <Breadcrumb.Item href="/dashboard">
            <Icon.HomeOutlined />
          </Breadcrumb.Item>
          {props.pages.map((page, key) => (
            <Breadcrumb.Item href={page.path} key={key}>
              {page.name && <span>{page.name}</span>}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <Card bordered={false} style={{ borderRadius: "10px" }}>
          {props.children}
        </Card>
      </div>
    </Layout.Content>
  );
}
