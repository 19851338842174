import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button, Modal, Typography } from "antd";
import * as Icon from "@ant-design/icons";

type Props = {
  channel: ConfigurationSlack;

  onRemove: () => void;
};

export default function RemoveButton(props: Props) {
  const { t } = useTranslation();

  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);

  async function remove() {
    setLoading(true);

    props.onRemove();
    setRemoving(false);

    setLoading(false);
  }

  return (
    <Fragment>
      <Button
        type="primary"
        danger
        icon={<Icon.DeleteOutlined />}
        size="small"
        style={{ marginLeft: "10px" }}
        onClick={() => setRemoving(true)}
      />

      <Modal
        footer={[
          <Button onClick={() => setRemoving(false)}>
            {t("actions.cancel")}
          </Button>,
          <Button danger loading={loading} type="primary" onClick={remove}>
            {t("actions.delete")}
          </Button>,
        ]}
        title={t(
          "pages.configuration.tabs.slack.testChannel.modals.remove.title"
        )}
        visible={removing}
        onCancel={() => setRemoving(false)}
      >
        <Typography.Paragraph>
          {t(
            "pages.configuration.tabs.slack.testChannel.modals.remove.description"
          )}
        </Typography.Paragraph>
      </Modal>
    </Fragment>
  );
}
