import axios from "axios";

import { Result } from "../utils/Result";

import { apiUrl } from "index";

import { getHeaders } from "./auth";

async function create(body: {
  channel: Channel;
  name: string;
  templateId: number;
}): Promise<Result<Action>> {
  return axios
    .post(`${apiUrl}/actions`, body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function getAll(): Promise<Result<Array<Action>>> {
  return axios
    .get(`${apiUrl}/actions`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function remove(id: number): Promise<Result<void>> {
  return axios
    .delete(`${apiUrl}/actions/${id}`, getHeaders())
    .then(() => {
      return { success: true as const, value: undefined };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

const actionService = { create, getAll, remove };
export default actionService;
