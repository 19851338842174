import { useTranslation } from "react-i18next";

// COMPONENTS
import { Typography } from "antd";

import AddButton from "./testChannel/AddButton";
import EditButton from "./testChannel/EditButton";
import RemoveButton from "./testChannel/RemoveButton";

type Props = {
  channels: Array<ConfigurationSlack>;
  testChannel: ConfigurationSlack | null;

  onDefine: (id: number) => void;
  onRemove: () => void;
};

export default function TestChannel(props: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Typography.Title level={5}>
        {t("pages.configuration.tabs.slack.testChannel.title")}
      </Typography.Title>

      {props.testChannel === null ? (
        <div>
          <Typography.Paragraph>
            {t("pages.configuration.tabs.slack.testChannel.empty")}
          </Typography.Paragraph>

          <AddButton channels={props.channels} onAdd={props.onDefine} />
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            borderTop: "1px solid rgba(0, 0, 0, .2)",
            display: "flex",
            padding: "10px 0",
          }}
        >
          <Typography.Text>{props.testChannel.name}</Typography.Text>

          <div style={{ marginLeft: "auto" }}>
            <EditButton
              channel={props.testChannel}
              channels={props.channels}
              onEdit={props.onDefine}
            />
            <RemoveButton
              channel={props.testChannel}
              onRemove={props.onRemove}
            />
          </div>
        </div>
      )}
    </div>
  );
}
