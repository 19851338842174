import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "antd/dist/antd.css";
import "./index.css";

import "./i18n";

import reportWebVitals from "./reportWebVitals";

// PAGES
// == PARAMETERS
import ProjectApi from "pages/admin/parameters/project/Api";
import ProjectInformations from "pages/admin/parameters/project/Informations";
import ProjectUsers from "pages/admin/parameters/project/Users";
import ProjectUser from "pages/admin/parameters/project/User";

import AccountInformations from "pages/admin/parameters/account/Informations";
import AccountEmail from "pages/admin/parameters/account/Email";
import AccountSecurity from "pages/admin/parameters/account/Security";
// == PARAMETERS

import Actions from "pages/admin/Actions";
import Configuration from "pages/admin/Configuration";
import Dashboard from "pages/admin/Dashboard";
import History from "pages/admin/History";
import NewProject from "pages/admin/NewProject";
import Templates from "pages/admin/Templates";

import ConfirmEmail from "pages/ConfirmEmail";
import Invitation from "pages/Invitation";
import Login from "pages/Login";
import Register from "pages/Register";
import ResetPasswordConfirm from "pages/ResetPasswordConfirm";
import ResetPasswordRequest from "pages/ResetPasswordRequest";

// export const apiUrl = "http://localhost:21000";
export const apiUrl = "https://back.nowtify.io";

const Root = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          {/* ==== PARAMETERS ==== */}
          <Route
            path="/parameters"
            element={<Navigate to="/parameters/project-informations" />}
          />
          {/* PROJECT */}
          <Route
            path="/parameters/project-informations"
            element={<ProjectInformations />}
          />
          <Route path="/parameters/project-users" element={<ProjectUsers />} />
          <Route
            path="/parameters/project-users/:id"
            element={<ProjectUser />}
          />
          <Route path="/parameters/project-api" element={<ProjectApi />} />

          {/* ACCOUNT */}
          <Route
            path="/parameters/account-informations"
            element={<AccountInformations />}
          />
          <Route path="/parameters/account-email" element={<AccountEmail />} />
          <Route
            path="/parameters/account-security"
            element={<AccountSecurity />}
          />
          {/* ==== PARAMETERS ==== */}

          <Route path="/actions" element={<Actions />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/history" element={<History />} />
          <Route path="/new-project" element={<NewProject />} />
          <Route path="/templates" element={<Templates />} />

          <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
          <Route path="/invitation/:token" element={<Invitation />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPasswordRequest />} />
          <Route
            path="/reset-password/:token"
            element={<ResetPasswordConfirm />}
          />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
