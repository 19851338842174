import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import configurationEmailService from "services/configurationEmail";

// COMPONENTS
import { Button } from "antd";
import * as Icon from "@ant-design/icons";

import Select from "components/Select";

import Mailtrap from "./form/Mailtrap";
import Nodemailer from "./form/Nodemailer";
import Sendinblue from "./form/Sendinblue";

type Props = {
  options: Array<{
    label: string;
    logo: string;
    value: "mailtrap" | "sendinblue" | "nodemailer";
    defaultValue: any;
  }>;
  parameters: ConfigurationEmail;

  onCancel: () => void;
  onUpdate: (value: ConfigurationEmail) => void;
};

export default function Form(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState(props.parameters);
  const [loading, setLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  async function save() {
    setLoading(true);
    console.log(form);

    await configurationEmailService.update(form).then((response) => {
      if (response.success) {
        props.onUpdate(response.value);
      }
    });

    setLoading(false);
  }
  return (
    <div>
      <div>
        <Select
          isMultiple={false}
          placeholder={t(
            "pages.configuration.tabs.email.parameters.type.placeholder"
          )}
          options={props.options.map((option) => ({
            label: (
              <div>
                <img
                  alt={option.value}
                  src={option.logo}
                  style={{ marginRight: "5px" }}
                  width="20px"
                />{" "}
                {option.label}
              </div>
            ),
            value: option.value,
          }))}
          value={form.service}
          width="200px"
          onChange={(value) =>
            setForm({
              ...form,
              service: value,
              credentials: props.options.find((item) => item.value === value)
                ?.defaultValue,
            })
          }
        />

        {/* TODO : help for each case */}
        {form.service && (
          <Button
            icon={<Icon.QuestionCircleFilled />}
            style={{ color: "rgba(0, 0, 0, .3)" }}
            type="link"
            onClick={() => setShowHelp(true)}
          />
        )}
      </div>

      {form.service && (
        <div style={{ marginTop: "1em" }}>
          {(() => {
            switch (form.service) {
              case "mailtrap":
                return (
                  <Mailtrap
                    credentials={form.credentials}
                    showHelp={showHelp}
                    onChange={(value) =>
                      setForm({ ...form, credentials: value })
                    }
                    onCloseHelp={() => setShowHelp(false)}
                  />
                );
              case "nodemailer":
                return (
                  <Nodemailer
                    credentials={form.credentials}
                    showHelp={showHelp}
                    onChange={(value) =>
                      setForm({ ...form, credentials: value })
                    }
                    onCloseHelp={() => setShowHelp(false)}
                  />
                );
              case "sendinblue":
                return (
                  <Sendinblue
                    credentials={form.credentials}
                    showHelp={showHelp}
                    onChange={(value) =>
                      setForm({ ...form, credentials: value })
                    }
                    onCloseHelp={() => setShowHelp(false)}
                  />
                );
            }
          })()}
        </div>
      )}

      <div style={{ marginTop: "1em" }}>
        <Button
          loading={loading}
          style={{ marginRight: "1em" }}
          type="primary"
          onClick={save}
        >
          {t("actions.save")}
        </Button>
        <Button onClick={props.onCancel}>{t("actions.cancel")}</Button>
      </div>
    </div>
  );
}
