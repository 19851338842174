const resetPasswordConfirm = {
  action: "Enregister",
  resetOk: {
    title: "Le mot de passe a bien été modifié",
    button: "Se connecter",
  },
  expiredToken: "Token expiré",
  fields: {
    password: {
      label: "Nouveau mot de passe",
      placeholder: "Nouveau mot de passe",
      error: "Le mot de passe doit contenir au moins 8 caractères",
    },
    confirmationPassword: {
      label: "Confirmation du mot de passe",
      placeholder: "Confirmation du mot de passe",
      error: "La confirmation doit être identique au mot de passe",
    },
  },

  title: "Réinitialisation de mot de passe",
};

export default resetPasswordConfirm;
