import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// SERVICES
import userService from "services/user";

// COMPONENTS
import { Button, Card, Col, Form, Row, Typography } from "antd";

import PublicLayout from "layouts/PublicLayout";

import Input from "components/Input";
import Spinner from "components/Spinner";

export default function Invitiation() {
  const { t } = useTranslation();

  const { token } = useParams<{ token: string }>();

  const [fetching, setFetching] = useState(true);
  const [isTokenOk, setIsTokenOk] = useState(false);
  const [resetOk, setResetOk] = useState(false);

  const [form, setForm] = useState({ password: "", confirmationPassword: "" });
  const [errors, setErrors] = useState({
    password: false,
    confirmationPassword: false,
  });
  const [loading, setLoading] = useState(false);

  async function checkToken() {
    await userService.checkPasswordToken(token || "").then((response) => {
      setIsTokenOk(response.success);
    });

    setFetching(false);
  }

  useEffect(() => {
    checkToken();
  }, []);

  async function reset() {
    setLoading(true);
    setErrors({
      password: false,
      confirmationPassword: false,
    });

    const newErrors = {
      confirmationPassword:
        form.password !== "" && form.password !== form.confirmationPassword,
      password: form.password === "" || form.password.length < 8,
    };
    setErrors(newErrors);

    if (!newErrors.confirmationPassword && !newErrors.password) {
      await userService
        .confirmResetPassword({ token: token || "", body: form })
        .then((response) => {
          setResetOk(response.success);
        });
    }

    setLoading(false);
  }

  return (
    <PublicLayout>
      <div
        style={{ alignItems: "center", display: "flex", minHeight: "100vh" }}
      >
        <Card
          bordered={false}
          style={{
            margin: "auto",
            maxWidth: "500px",
            width: "50%",
          }}
        >
          <Spinner spinning={fetching} data={{}}>
            <Typography.Title level={3}>
              {t("pages.resetPasswordConfirm.title")}
            </Typography.Title>

            {!isTokenOk ? (
              <Typography.Paragraph style={{ margin: 0 }}>
                {t("pages.resetPasswordConfirm.expiredToken")}
              </Typography.Paragraph>
            ) : !resetOk ? (
              <div>
                <Form layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <Input
                        error={errors.password}
                        errorMessage={
                          form.password !== ""
                            ? t(
                                "pages.resetPasswordConfirm.fields.password.error"
                              )
                            : undefined
                        }
                        label={t(
                          "pages.resetPasswordConfirm.fields.password.label"
                        )}
                        placeholder={t(
                          "pages.resetPasswordConfirm.fields.password.placeholder"
                        )}
                        style={{ margin: 0 }}
                        type="password"
                        value={form.password}
                        onChange={(value) =>
                          setForm({
                            ...form,
                            password: value,
                          })
                        }
                      />
                    </Col>

                    <Col xs={24} md={12}>
                      <Input
                        error={errors.confirmationPassword}
                        errorMessage={
                          form.confirmationPassword !== ""
                            ? t(
                                "pages.resetPasswordConfirm.fields.confirmationPassword.error"
                              )
                            : undefined
                        }
                        label={t(
                          "pages.resetPasswordConfirm.fields.confirmationPassword.label"
                        )}
                        placeholder={t(
                          "pages.resetPasswordConfirm.fields.confirmationPassword.placeholder"
                        )}
                        style={{ margin: 0 }}
                        type="password"
                        value={form.confirmationPassword}
                        onChange={(value) =>
                          setForm({
                            ...form,
                            confirmationPassword: value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form>

                <br />
                <Button
                  loading={loading}
                  style={{ display: "block", margin: "0 auto" }}
                  type="primary"
                  onClick={reset}
                >
                  {t("pages.resetPasswordConfirm.action")}
                </Button>
              </div>
            ) : (
              <div>
                <Typography.Paragraph style={{ textAlign: "center" }}>
                  {t("pages.resetPasswordConfirm.resetOk.title")}
                </Typography.Paragraph>

                <Link to="/">
                  <Button
                    style={{ display: "block", margin: "0 auto" }}
                    type="primary"
                  >
                    {t("pages.resetPasswordConfirm.resetOk.button")}
                  </Button>
                </Link>
              </div>
            )}
          </Spinner>
        </Card>
      </div>
    </PublicLayout>
  );
}
