import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import configurationSlackService from "services/configurationSlack";

// COMPONENTS
import { Button, Modal } from "antd";

import Form from "./Form";

type Props = {
  isVisible: boolean;

  onAdd: (channel: ConfigurationSlack) => void;
  onClose: () => void;
};

export default function AddPopup(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState({ name: "", url: "" });
  const [loading, setLoading] = useState(false);

  async function save() {
    setLoading(true);

    const body = {
      name: form.name,
      url: form.url,
      type: "production" as const,
    };

    await configurationSlackService.create(body).then((response) => {
      if (response.success) {
        props.onAdd(response.value);
        setForm({ name: "", url: "" });
      }
    });

    setLoading(false);
  }

  return (
    <Modal
      footer={[
        <Button onClick={props.onClose}>{t("actions.cancel")}</Button>,
        <Button loading={loading} type="primary" onClick={save}>
          {t("actions.save")}
        </Button>,
      ]}
      title={t("pages.configuration.tabs.slack.modals.add.title")}
      visible={props.isVisible}
      onCancel={props.onClose}
    >
      <Form form={form} onChange={setForm} />
    </Modal>
  );
}
