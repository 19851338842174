import { useTranslation } from "react-i18next";

// COMPONENTS
import { Typography } from "antd";

type Props = {
  credentials: (ConfigurationEmail & {
    type: Extract<ConfigurationEmail["type"], "test">;
    service: Extract<ConfigurationEmail["service"], "mailtrap">;
  })["credentials"];
};

export default function Mailtrap(props: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Typography.Paragraph style={{ margin: 0 }}>
        {t("pages.configuration.tabs.email.parameters.mailtrap.username")} :{" "}
        {props.credentials.user}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {t("pages.configuration.tabs.email.parameters.mailtrap.password")} :{" "}
        {props.credentials.pass}
      </Typography.Paragraph>
    </div>
  );
}
