// UTILS
import { firstLetter } from "utils/texts";

// COMPONENTS
import { Avatar } from "antd";
import * as Icon from "@ant-design/icons";

type Props = {
  loading: boolean;
  project: SimpleProject;

  onSwitch: (id: number) => void;
};

export default function OtherProject(props: Props) {
  return (
    <div
      style={{
        backgroundColor: "#f0f2f5",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        color: "rgba(0, 0, 0, 0.5)",
        cursor: "pointer",
        padding: "1em 10px",
      }}
      onClick={() => props.onSwitch(props.project.id)}
    >
      {!props.loading ? (
        <Avatar
          size="small"
          style={{ marginRight: "10px" }}
          src={props.project.logo}
        >
          {firstLetter(props.project.name)}
        </Avatar>
      ) : (
        <Icon.LoadingOutlined
          style={{
            height: "24px",
            marginRight: "10px",
            lineHeight: "24px",
            width: "24px",
          }}
        />
      )}
      {props.project.name}
    </div>
  );
}
