import { Fragment, useState } from "react";

// COMPONENTS
import { Button } from "antd";
import * as Icon from "@ant-design/icons";
import EditPopup from "./editButton/EditPopup";

type Props = {
  channel: ConfigurationSlack;
  channels: Array<ConfigurationSlack>;

  onEdit: (id: number) => void;
};

export default function EditButton(props: Props) {
  const [editing, setEditing] = useState(false);

  function edit(id: number) {
    props.onEdit(id);
    setEditing(false);
  }

  return (
    <Fragment>
      <Button
        type="primary"
        icon={<Icon.EditOutlined />}
        size="small"
        onClick={() => setEditing(true)}
      />

      <EditPopup
        channel={props.channel}
        channels={props.channels}
        isVisible={editing}
        onClose={() => setEditing(false)}
        onEdit={edit}
      />
    </Fragment>
  );
}
