import { useTranslation } from "react-i18next";

// COMPONENTS
import { Typography } from "antd";

type Props = {
  credentials: (ConfigurationEmail & {
    type: Extract<ConfigurationEmail["type"], "production">;
    service: Extract<ConfigurationEmail["service"], "sendinblue">;
  })["credentials"];
};

export default function Sendinblue(props: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Typography.Paragraph style={{ margin: 0 }}>
        {t("pages.configuration.tabs.email.parameters.sendinblue.key")} :{" "}
        {props.credentials.key}
      </Typography.Paragraph>
    </div>
  );
}
