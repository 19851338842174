import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import configurationSlackService from "services/configurationSlack";

// COMPONENTS
import { Button, Modal } from "antd";

import Form from "../Form";

type Props = {
  channel: ConfigurationSlack;
  channels: Array<ConfigurationSlack>;
  isVisible: boolean;

  onEdit: (id: number) => void;
  onClose: () => void;
};

export default function EditPopup(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    channel: props.channel.id,
  });
  const [loading, setLoading] = useState(false);

  async function save() {
    setLoading(true);

    const id = form.channel;
    const body = { type: "test" as const };
    await configurationSlackService.update({ id, body }).then((response) => {
      if (response.success) {
        props.onEdit(id);
      }
    });

    setLoading(false);
  }

  return (
    <Modal
      footer={[
        <Button onClick={props.onClose}>{t("actions.cancel")}</Button>,
        <Button loading={loading} type="primary" onClick={save}>
          {t("actions.save")}
        </Button>,
      ]}
      title={t("pages.configuration.tabs.slack.testChannel.modals.edit.title", {
        name: props.channel.name,
      })}
      visible={props.isVisible}
      onCancel={props.onClose}
    >
      <Form
        channel={form.channel}
        channels={props.channels}
        onChange={(id) => setForm({ channel: id })}
      />
    </Modal>
  );
}
