import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import actionService from "services/action";
import templateService from "services/template";

// COMPONENTS
import { Button, Modal } from "antd";

import Form from "../Form";

type Props = {
  isVisible: boolean;

  onAdd: (action: Action) => void;
  onClose: () => void;
};

export default function AddPopup(props: Props) {
  const { t } = useTranslation();

  const [templates, setTemplates] = useState<Array<Template>>([]);
  const [form, setForm] = useState<{
    name: string;
    channel: Channel;
    templateId: number;
  }>({
    name: "",
    channel: "email",
    templateId: 0,
  });
  const [loading, setLoading] = useState(false);

  async function fetchTemplates() {
    await templateService.getAll().then((response) => {
      if (response.success) {
        setTemplates(response.value);
        if (response.value.length > 0) {
          setForm({ ...form, templateId: response.value[0].id });
        }
      }
    });
  }

  useEffect(() => {
    fetchTemplates();
  }, []);

  async function remove() {
    setLoading(true);

    if (form.templateId !== 0) {
      await actionService.create(form).then((response) => {
        if (response.success) {
          props.onAdd(response.value);
        }
      });

      props.onClose();
    }

    setLoading(false);
  }

  return (
    <Modal
      footer={[
        <Button onClick={() => props.onClose()}>{t("actions.cancel")}</Button>,
        <Button loading={loading} type="primary" onClick={remove}>
          {t("actions.save")}
        </Button>,
      ]}
      title={t("pages.actions.modals.add.title")}
      visible={props.isVisible}
      onCancel={() => props.onClose()}
    >
      <Form form={form} templates={templates} onChange={setForm} />
    </Modal>
  );
}
