import parse from "html-react-parser";

// SERVICES
import authService from "services/auth";

type Props = {
  content: string;
};

export default function EmailPreview(props: Props) {
  const user = authService.getAuthenticatedUser();

  function displayParameter(parameter: "logo" | "signature" | "name"): string {
    if (user !== null && user.project.parameters) {
      const data = user.project.parameters[parameter];
      return data !== null ? data : "";
    } else {
      return "";
    }
  }

  return (
    <div>
      <body
        style={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 300,
          boxSizing: "border-box",
          color: "#74787E",
          height: "100%",
          hyphens: "auto",
          lineHeight: 1.4,
          margin: 0,
          wordBreak: "break-word",
          width: " 100%",
        }}
      >
        <table
          className="wrapper"
          style={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 300,
            boxSizing: "border-box",
            margin: 0,
            padding: 0,
            width: " 100%",
            backgroundColor: "#f5f8fa",
          }}
          width="100%"
        >
          <tr>
            <td
              align="center"
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 300,
                boxSizing: "border-box",
              }}
            >
              <table
                className="content"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 300,
                  boxSizing: "border-box",
                  margin: 0,
                  padding: 0,
                  width: "100%",
                }}
                width="100%"
              >
                <tr>
                  <td
                    className="header"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 300,
                      boxSizing: "border-box",
                      padding: "25px 0",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        boxSizing: "border-box",
                        color: "#bbbfc3",
                        fontSize: "19px",
                        fontWeight: "bold",
                        textDecoration: "none",
                        textShadow: "0 1px 0 white",
                      }}
                    >
                      <img
                        className="img-valign"
                        src={displayParameter("logo")}
                        style={{
                          maxWidth: "100%",
                          border: "none",
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: 300,
                          boxSizing: "border-box",
                          verticalAlign: "middle",
                          width: "200px",
                        }}
                        alt={displayParameter("name")}
                        width="200"
                      />
                    </a>
                  </td>
                </tr>

                <tr>
                  <td
                    className="body"
                    width="100%"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 300,
                      boxSizing: "border-box",
                      backgroundColor: "#FFFFFF",
                      borderBottom: "1px solid #EDEFF2",
                      borderTop: "1px solid #EDEFF2",
                      margin: 0,
                      padding: 0,
                      width: "100%",
                    }}
                  >
                    <table
                      className="inner-body"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 300,
                        boxSizing: "border-box",
                        backgroundColor: " #FFFFFF",
                        margin: "0 auto",
                        padding: 0,
                        width: "640px",
                      }}
                    >
                      <tr>
                        <td
                          className="content-cell"
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 300,
                            boxSizing: "border-box",
                            padding: "35px",
                          }}
                        >
                          {parse(props.content)}

                          <p>{displayParameter("signature")}</p>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 300,
                      boxSizing: "border-box",
                    }}
                  >
                    <table
                      className="footer"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 300,
                        boxSizing: "border-box",
                        margin: "0 auto",
                        padding: 0,
                        textAlign: "center",
                        width: "570px",
                      }}
                    >
                      <tr>
                        <td
                          className="content-cell"
                          align="center"
                          style={{
                            fontFamily: " 'Poppins', sans-serif",
                            fontWeight: 300,
                            boxSizing: "border-box",
                            padding: "35px",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "1.5em",
                              marginTop: 0,
                              fontFamily: "'Poppins', sans-serif",
                              fontWeight: 300,
                              boxSizing: "border-box",
                              color: "#AEAEAE",
                              fontSize: "12px",
                              margin: 0,
                              textAlign: "center",
                            }}
                          >
                            &copy; {new Date().getFullYear()}{" "}
                            {user !== null ? user.project.name : ""}. Tous
                            droits réservés.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </body>
    </div>
  );
}
