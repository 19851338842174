import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button } from "antd";

import AddPopup from "./addButton/AddPopup";

type Props = {
  channels: Array<ConfigurationSlack>;

  onAdd: (id: number) => void;
};

export default function AddButton(props: Props) {
  const { t } = useTranslation();

  const [adding, setAdding] = useState(false);

  function add(id: number) {
    props.onAdd(id);
    setAdding(false);
  }

  return (
    <Fragment>
      <Button type="primary" onClick={() => setAdding(true)}>
        {t("pages.configuration.tabs.slack.testChannel.actions.add")}
      </Button>

      <AddPopup
        channels={props.channels}
        isVisible={adding}
        onAdd={add}
        onClose={() => setAdding(false)}
      />
    </Fragment>
  );
}
