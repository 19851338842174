import { useTranslation } from "react-i18next";

// COMPONENTS
import Select from "components/Select";

type Props = {
  channel: number | undefined;
  channels: Array<ConfigurationSlack>;

  onChange: (id: number) => void;
};
export default function Form(props: Props) {
  const { t } = useTranslation();

  return (
    <Select
      isMultiple={false}
      options={props.channels.map((item) => ({
        label: item.name,
        value: item.id.toString(),
      }))}
      placeholder={t("pages.configuration.tabs.slack.testChannel.form.choose")}
      value={props.channel !== undefined ? props.channel.toString() : undefined}
      width="100%"
      onChange={(value) => props.onChange(parseInt(value))}
    />
  );
}
