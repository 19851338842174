import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import configurationSlackService from "services/configurationSlack";

// COMPONENTS
import { Button, Modal } from "antd";

import Form from "../../../Form";

type Props = {
  channel: ConfigurationSlack;
  isVisible: boolean;

  onEdit: (channel: ConfigurationSlack) => void;
  onClose: () => void;
};

export default function EditPopup(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    name: props.channel.name,
    url: props.channel.url,
  });
  const [loading, setLoading] = useState(false);

  async function save() {
    setLoading(true);

    const body = {
      name: form.name,
      url: form.url,
    };

    await configurationSlackService
      .update({ id: props.channel.id, body })
      .then((response) => {
        if (response.success) {
          props.onEdit(response.value);
        }
      });

    setLoading(false);
  }

  return (
    <Modal
      footer={[
        <Button onClick={props.onClose}>{t("actions.cancel")}</Button>,
        <Button loading={loading} type="primary" onClick={save}>
          {t("actions.save")}
        </Button>,
      ]}
      title={t("pages.configuration.tabs.slack.modals.edit.title", {
        name: props.channel.name,
      })}
      visible={props.isVisible}
      onCancel={props.onClose}
    >
      <Form form={form} onChange={setForm} />
    </Modal>
  );
}
