import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button } from "antd";

import AddPopup from "./addButton/AddPopup";

type Props = {
  onAdd: (action: Action) => void;
};

export default function RemoveButton(props: Props) {
  const { t } = useTranslation();

  const [adding, setAdding] = useState(false);

  return (
    <Fragment>
      <Button
        type="primary"
        style={{ marginBottom: "1em" }}
        onClick={() => setAdding(true)}
      >
        {t("pages.actions.actions.add")}
      </Button>

      <AddPopup
        isVisible={adding}
        onAdd={props.onAdd}
        onClose={() => setAdding(false)}
      />
    </Fragment>
  );
}
