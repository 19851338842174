const confirmEmail = {
  acceptedInvitation: {
    title: "L'email' a été confirmée",
    description: "Vous pouvez dès à présent vous connecter",
    button: "Se connecter",
  },
  expiredToken: "Token expiré",
  title: "Confirmer l'email",
};

export default confirmEmail;
