import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import projectService from "services/project";

// COMPONENTS
import { Button, Modal } from "antd";

type Props = {
  currentUser: AuthenticatedUser;
  isVisible: boolean;
  user: ProjectUser;

  onClose: () => void;
  onReactivate: (user: ProjectUser) => void;
};

export default function ReactivatePopup(props: Props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  async function reactivate() {
    setLoading(true);

    await projectService.activate(props.user.id).then((response) => {
      if (response.success) {
        props.onReactivate(response.value);
      }
    });

    setLoading(false);
  }

  return (
    <Modal
      centered
      footer={[
        <Button onClick={props.onClose}>{t("actions.cancel")}</Button>,
        <Button type="primary" loading={loading} onClick={reactivate}>
          {t(
            "pages.parameters.tabs.project.users.table.actions.cases.reactivate"
          )}
        </Button>,
      ]}
      title={t("pages.parameters.tabs.project.users.modals.reactivate.title", {
        name: `${props.user.firstName} ${props.user.lastName}`,
      })}
      visible={props.isVisible}
      onCancel={props.onClose}
    >
      {t("pages.parameters.tabs.project.users.modals.reactivate.description", {
        project: props.currentUser.project.name,
      })}
    </Modal>
  );
}
