const actions = {
  title: "Actions",
  actions: { add: "Ajouter une action" },
  table: {
    actions: "Actions",
    channel: "Type",
    id: "ID",
    name: "Nom",
    template: "Template",
  },
  modals: {
    add: {
      title: "Ajouter une action",
      fields: {
        name: { label: "Nom", placeholder: "Nom" },
        channel: { label: "Type", placeholder: "Choisir un type" },
        template: {
          label: "Template associée",
          placeholder: "Choisir une template",
        },
      },
    },
    action: {
      title: 'Action "{{name}}"',
      variables: {
        empty: "Aucune variable",
        title: "Liste des variables",
      },
    },
    remove: {
      title: 'Supprimer l\'action "{{name}}"',
      description: "Êtes-vous sûr•e de vouloir supprimer cette action ?",
    },
  },
};

export default actions;
