import { useTranslation } from "react-i18next";

// UTILS
import { DD_MM_YYYY_HH_MM_SS } from "utils/dates";

// COMPONENTS
import { Alert, Modal, Tag, Typography } from "antd";

import Redo from "./detailsPopup/Redo";
type Props = {
  isVisible: boolean;
  history: DataHistory;

  onClose: () => void;
  onRedo: () => void;
};

export default function DetailsPopup(props: Props) {
  const { t } = useTranslation();

  const statusTag = (status: DataHistory["status"]) => (
    <Tag color={t(`pages.history.status.${status}.color`)}>
      {t(`pages.history.status.${status}.label`)}
    </Tag>
  );

  return (
    <Modal
      centered={true}
      footer={false}
      visible={props.isVisible}
      onCancel={props.onClose}
    >
      <div style={{ alignItems: "center", display: "flex" }}>
        <Typography.Title level={5} style={{ margin: "0 .5em 0 0" }}>
          {t("pages.history.modal.title", { id: props.history.id })}
        </Typography.Title>

        {statusTag(props.history.status)}
      </div>

      <Typography.Paragraph>
        {props.history.channel}
        <br />
        {props.history.recipient} <br />
        {t("pages.history.modal.data.createdAt")}{" "}
        {DD_MM_YYYY_HH_MM_SS(props.history.createdAt)} <br />
        {t("pages.history.modal.data.lastUpdate")}{" "}
        {DD_MM_YYYY_HH_MM_SS(props.history.updatedAt)}
      </Typography.Paragraph>

      {props.history.status === "error" && (
        <div>
          <Alert
            description={
              props.history.error.type === "missing_data"
                ? props.history.error.message.join(", ")
                : undefined
            }
            message={t(
              `pages.history.modal.errors.${props.history.error.type}`
            )}
            style={{ marginBottom: "1em" }}
            type="error"
          />

          <Redo
            error={props.history.error}
            historyId={props.history.id}
            onRedo={props.onRedo}
          />
        </div>
      )}
    </Modal>
  );
}
