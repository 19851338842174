import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import authService from "services/auth";
import projectService from "services/project";

// COMPONENTS
import { Button, Spin } from "antd";
import * as Icon from "@ant-design/icons";

import InvitePopup from "./users/InvitePopup";
import Table from "./users/Table";
import Parameters from "../../Parameters";

export default function Users() {
  const { t } = useTranslation();

  const [users, setUsers] = useState<Project["users"]>([]);
  const [fetching, setFetching] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  async function fetchUsers() {
    await projectService.getOne().then((response) => {
      if (response.success) {
        setUsers(response.value.users);
      }
    });

    setFetching(false);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  function addUser(newUser: ProjectUser) {
    setUsers([...users, newUser]);
    setShowPopup(false);
  }

  return (
    <Parameters
      pages={[
        {
          name: t("pages.parameters.title"),
          path: "/parameters",
        },
        {
          name: t("pages.parameters.navigation.project.items.users"),
          path: "/parameters/project-users",
        },
      ]}
      title={t("pages.parameters.navigation.project.items.users")}
    >
      {authService.hasRank(["admin"]) && (
        <Button
          icon={<Icon.PlusOutlined />}
          style={{ display: "block", marginLeft: "auto" }}
          type="primary"
          onClick={() => setShowPopup(true)}
        >
          {t("pages.parameters.tabs.project.users.actions.invite")}
        </Button>
      )}

      <InvitePopup
        isVisible={showPopup}
        onClose={() => setShowPopup(false)}
        onInvite={addUser}
      />

      <Spin spinning={fetching}>
        <Table users={users} onUpdate={(users) => setUsers(users)} />
      </Spin>
    </Parameters>
  );
}
