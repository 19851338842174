import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// SERVICES
import projectService from "services/project";

// COMPONENTS
import { Button, Col, Form, Row, Typography } from "antd";

import Input from "components/Input";

type Props = {
  token: string;
  project: { id: number; name: string };
  user: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    email: string;
  };

  onAccept: (value: ProjectUser) => void;
};

export default function Invitiation(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState({ firstName: "", lastName: "" });
  const [errors, setErrors] = useState({ firstName: false, lastName: false });
  const [loading, setLoading] = useState(false);

  const fields: Array<"firstName" | "lastName"> = ["firstName", "lastName"];

  async function save() {
    setLoading(true);
    setErrors({ firstName: false, lastName: false });

    const newErrors = {
      firstName: form.firstName === "",
      lastName: form.lastName === "",
    };
    setErrors(newErrors);

    if (!newErrors.firstName && !newErrors.lastName) {
      await projectService
        .acceptInvitation({ token: props.token, body: form })
        .then((response) => {
          if (response.success) {
            props.onAccept(response.value);
          }
        });
    }

    setLoading(false);
  }

  return props.user.firstName !== null && props.user.lastName !== null ? (
    <div>
      <Typography.Title level={5}>
        {t("pages.invitation.acceptedInvitation.title")}
      </Typography.Title>
      <Typography.Paragraph>
        {t("pages.invitation.acceptedInvitation.description", {
          name: props.project.name,
        })}
      </Typography.Paragraph>

      <Link to="/">
        <Button type="primary">
          {t("pages.invitation.acceptedInvitation.button")}
        </Button>
      </Link>
    </div>
  ) : (
    <div>
      <Typography.Paragraph>
        {t("pages.invitation.register.description")}
      </Typography.Paragraph>

      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          {fields.map((field) => (
            <Col xs={24} md={12}>
              <Input
                error={errors[field]}
                errorMessage={t(
                  `pages.invitation.register.form.${field}.error`
                )}
                label={t(`pages.invitation.register.form.${field}.label`)}
                name={field}
                placeholder={t(
                  `pages.invitation.register.form.${field}.placeholder`
                )}
                value={form[field]}
                onChange={(value) => setForm({ ...form, [field]: value })}
              />
            </Col>
          ))}
        </Row>

        <Button
          loading={loading}
          style={{ display: "block", margin: "0 auto" }}
          type="primary"
          onClick={save}
        >
          {t("actions.save")}
        </Button>
      </Form>
    </div>
  );
}
