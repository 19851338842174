import axios from "axios";

import { Result } from "../utils/Result";

import { apiUrl } from "index";

import { getHeaders } from "./auth";

async function getAll(): Promise<
  Result<{
    test:
      | (ConfigurationEmail & {
          type: Extract<ConfigurationEmail["type"], "test">;
        })
      | null;
    production:
      | (ConfigurationEmail & {
          type: Extract<ConfigurationEmail["type"], "production">;
        })
      | null;
  }>
> {
  return axios
    .get(`${apiUrl}/configuration-email`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function update(
  body: ConfigurationEmail
): Promise<Result<ConfigurationEmail>> {
  return axios
    .put(`${apiUrl}/configuration-email`, body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: "" };
    });
}

const configurationEmailService = { getAll, update };
export default configurationEmailService;
