// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tab-container p {
  margin: 0;
}
.tab-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.tab-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.tab-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.tab-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .tab-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.tab-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"] .tab-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,SAAS;AACX;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;;EAEE,uBAAuB;EACvB,yBAAyB;AAC3B;AACA;;EAEE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.tab-container p {\n  margin: 0;\n}\n.tab-container > .ant-tabs-card .ant-tabs-content {\n  margin-top: -16px;\n}\n.tab-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {\n  padding: 16px;\n  background: #fff;\n}\n.tab-container > .ant-tabs-card > .ant-tabs-nav::before {\n  display: none;\n}\n.tab-container > .ant-tabs-card .ant-tabs-tab,\n[data-theme=\"compact\"] .tab-container > .ant-tabs-card .ant-tabs-tab {\n  background: transparent;\n  border-color: transparent;\n}\n.tab-container > .ant-tabs-card .ant-tabs-tab-active,\n[data-theme=\"compact\"] .tab-container > .ant-tabs-card .ant-tabs-tab-active {\n  background: #fff;\n  border-color: #fff;\n}\n#components-tabs-demo-card-top .code-box-demo {\n  padding: 24px;\n  overflow: hidden;\n  background: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
