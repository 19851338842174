import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import authService from "services/auth";
import userService from "services/user";

// COMPONENTS
import { Col, Row } from "antd";

import FileEdit from "components/FileEdit";
import InputEdit from "components/InputEdit";

import Parameters from "../../Parameters";

export default function Informations() {
  const { t } = useTranslation();

  const [user, setUser] = useState<User | null>(
    authService.getAuthenticatedUser()
  );

  const [errors, setErrors] = useState({ firstName: false, lastName: false });

  async function save(body: { firstName?: string; lastName?: string }) {
    setErrors({ firstName: false, lastName: false });

    const newErrors = {
      firstName: body.firstName !== undefined && body.firstName === "",
      lastName: body.lastName !== undefined && body.lastName === "",
    };
    setErrors(newErrors);

    if (!newErrors.firstName && !newErrors.lastName) {
      await userService.update(body).then((response) => {
        if (response.success) {
          setUser(response.value);
          const authenticatedUser = authService.getAuthenticatedUser();
          if (authenticatedUser !== null) {
            authService.setUser({
              ...response.value,
              projects: authenticatedUser.projects,
              project: authenticatedUser.project,
            });
          }
        }
      });
    }
  }

  async function saveProfilePicture(formData: FormData) {
    await userService.updateProfilePicture(formData).then((response) => {
      if (response.success) {
        setUser(response.value);
        const authenticatedUser = authService.getAuthenticatedUser();
        if (authenticatedUser !== null) {
          authService.setUser({
            ...response.value,
            projects: authenticatedUser.projects,
            project: authenticatedUser.project,
          });
        }
      }
    });
  }

  return (
    <Parameters
      pages={[
        {
          name: t("pages.parameters.title"),
          path: "/parameters",
        },
        {
          name: t("pages.parameters.navigation.account.items.informations"),
          path: "/parameters/account-informations",
        },
      ]}
      title={t("pages.parameters.navigation.account.items.informations")}
    >
      {user !== null && (
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12}>
            <InputEdit
              error={errors.firstName}
              label={t(
                "pages.parameters.tabs.account.informations.fields.firstName.label"
              )}
              placeholder={t(
                "pages.parameters.tabs.account.informations.fields.firstName.placeholder"
              )}
              value={user.firstName}
              onSave={(value) =>
                save({ firstName: value === null ? "" : value })
              }
            />
          </Col>

          <Col xs={24} sm={12}>
            <InputEdit
              error={errors.lastName}
              label={t(
                "pages.parameters.tabs.account.informations.fields.lastName.label"
              )}
              placeholder={t(
                "pages.parameters.tabs.account.informations.fields.lastName.placeholder"
              )}
              value={user.lastName}
              onSave={(value) =>
                save({ lastName: value === null ? "" : value })
              }
            />
          </Col>

          <Col xs={24} sm={12}>
            <FileEdit
              height="100px"
              label={t(
                "pages.parameters.tabs.account.informations.fields.profilePicture.label"
              )}
              placeholder={t(
                "pages.parameters.tabs.account.informations.fields.profilePicture.placeholder"
              )}
              file={user.profilePicture}
              type="avatar"
              width="100px"
              onSave={saveProfilePicture}
            />
          </Col>
        </Row>
      )}
    </Parameters>
  );
}
