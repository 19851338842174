import axios from "axios";

import { Result } from "../utils/Result";

import { apiUrl } from "index";

import { getHeaders } from "./auth";

async function getAll(params: {
  type?: "production" | "test";
  page?: number;
  number?: number;
}): Promise<Result<Pagination<DataHistory>>> {
  const query = [];
  if (params.type) query.push(`type=${params.type}`);
  if (params.page) query.push(`page=${params.page}`);
  if (params.number) query.push(`number=${params.number}`);

  return axios
    .get(
      `${apiUrl}/history${query.length > 0 ? `?${query.join("&")}` : ""}`,
      getHeaders()
    )
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function redo(params: {
  id: number;
  body: object;
}): Promise<Result<void>> {
  return axios
    .post(`${apiUrl}/history/${params.id}`, params.body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

const historyService = { getAll, redo };
export default historyService;
