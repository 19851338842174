import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import configurationSlackService from "services/configurationSlack";

// COMPONENTS
import { Button } from "antd";

import Spinner from "components/Spinner";

import AddPopup from "./slack/AddPopup";
import Channels from "./slack/Channels";
import TestChannel from "./slack/TestChannel";

export default function Slack() {
  const { t } = useTranslation();

  const [showPopup, setShowPoup] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [channels, setChannels] = useState<Array<ConfigurationSlack>>([]);
  const [testChannel, setTestChannel] = useState<ConfigurationSlack | null>(
    null
  );

  async function fetchConfiguration() {
    await configurationSlackService.getAll().then((response) => {
      if (response.success) {
        setChannels(response.value);
        const test = response.value.find((item) => item.type === "test");
        if (test !== undefined) {
          setTestChannel(test);
        }
      }
    });

    setFetching(false);
  }

  useEffect(() => {
    fetchConfiguration();
  }, []);

  function add(channel: ConfigurationSlack) {
    setShowPoup(false);
    setChannels([...channels, channel]);
  }

  function edit(channel: ConfigurationSlack) {
    setChannels(
      channels.map((item) => (item.id === channel.id ? channel : item))
    );
  }

  function remove(id: number) {
    const newChannels = channels.filter((item) => item.id !== id);
    setChannels(newChannels);
  }

  function defineTest(id: number) {
    const channel = channels.find((item) => item.id === id);
    if (channel) {
      setTestChannel(channel);
    }
  }

  return (
    <div>
      <Button type="primary" onClick={() => setShowPoup(true)}>
        {t("pages.configuration.tabs.slack.actions.add")}
      </Button>

      <AddPopup
        isVisible={showPopup}
        onAdd={add}
        onClose={() => setShowPoup(false)}
      />

      <br />
      <br />

      <Spinner data={channels} spinning={fetching}>
        <Fragment>
          {channels.length > 0 && (
            <TestChannel
              channels={channels}
              testChannel={testChannel}
              onDefine={defineTest}
              onRemove={() => setTestChannel(null)}
            />
          )}

          <br />

          <Channels
            channels={channels}
            testChannel={testChannel}
            onEdit={edit}
            onRemove={remove}
          />
        </Fragment>
      </Spinner>
    </div>
  );
}
