import { useTranslation } from "react-i18next";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Table } from "antd";

import Actions from "./table/Actions";
import User from "./table/User";

type Props = {
  users: Array<ProjectUser>;

  onUpdate: (users: Array<ProjectUser>) => void;
};

export default function UsersTable(props: Props) {
  const { t } = useTranslation();

  const columns = [
    {
      key: "user",
      title: t("pages.parameters.tabs.project.users.table.user"),
      render: (record: ProjectUser) => <User user={record} />,
    },
    {
      dataIndex: "rank",
      key: "rank",
      title: t("pages.parameters.tabs.project.users.table.rank"),
      render: (text: Rank) => t(`ranks.${text}.label`),
    },
    ...(authService.hasRank(["admin"])
      ? [
          {
            key: "actions",
            title: t("pages.parameters.tabs.project.users.table.actions.title"),
            render: (record: ProjectUser) => (
              <Actions
                user={record}
                onUpdate={(user) =>
                  props.onUpdate(
                    props.users.map((item) =>
                      item.id === user.id ? user : item
                    )
                  )
                }
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div style={{ marginTop: "1em" }}>
      <Table bordered={true} dataSource={props.users} columns={columns} />
    </div>
  );
}
