import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Alert, Button, Card, Col, Form, Row, Typography } from "antd";

import Input from "components/Input";

import PublicLayout from "layouts/PublicLayout";

export default function Register() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [form, setForm] = useState({
    user: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmationPassword: "",
    },
    project: {
      name: "",
    },
  });
  const [errors, setErrors] = useState({
    user: {
      email: false,
      firstName: false,
      lastName: false,
      password: false,
      confirmationPassword: false,
    },
    project: { name: false },
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  async function register() {
    setLoading(true);
    setErrors({
      user: {
        email: false,
        firstName: false,
        lastName: false,
        password: false,
        confirmationPassword: false,
      },
      project: { name: false },
    });

    const newErrors = {
      user: {
        email: form.user.email === "",
        firstName: form.user.firstName === "",
        lastName: form.user.lastName === "",
        confirmationPassword:
          form.user.password !== "" &&
          form.user.password !== form.user.confirmationPassword,
        password: form.user.password === "" || form.user.password.length < 8,
      },
      project: {
        name: form.project.name === "",
      },
    };
    setErrors(newErrors);

    if (
      !newErrors.user.email &&
      !newErrors.user.firstName &&
      !newErrors.user.lastName &&
      !newErrors.user.password &&
      !newErrors.user.confirmationPassword &&
      !newErrors.project.name
    ) {
      await authService.register(form).then((response) => {
        if (response.success) {
          navigate("/login?register=ok");
        } else {
          setError(response.message);
        }
      });
    }

    setLoading(false);
  }

  return (
    <PublicLayout>
      <div
        style={{ alignItems: "center", display: "flex", minHeight: "100vh" }}
      >
        <Card
          bordered={false}
          style={{
            margin: "auto",
            maxWidth: "500px",
            width: "50%",
          }}
        >
          <Typography.Title level={2} style={{ textAlign: "center" }}>
            {t("pages.register.title")}
          </Typography.Title>

          {error !== null && (
            <Alert
              message={t(`pages.register.errors.${error}`)}
              style={{ marginBottom: "1em" }}
              type="error"
              showIcon
            />
          )}

          <Form layout="vertical">
            <Typography.Title level={5}>
              {t("pages.register.fields.user.title")}
            </Typography.Title>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Input
                  error={errors.user.email}
                  errorMessage={t("pages.register.fields.user.email.error")}
                  label={t("pages.register.fields.user.email.label")}
                  placeholder={t(
                    "pages.register.fields.user.email.placeholder"
                  )}
                  style={{ margin: 0 }}
                  type="email"
                  value={form.user.email}
                  onChange={(value) =>
                    setForm({ ...form, user: { ...form.user, email: value } })
                  }
                />
              </Col>

              <Col xs={24} md={12}>
                <Input
                  error={errors.user.firstName}
                  errorMessage={t("pages.register.fields.user.firstName.error")}
                  label={t("pages.register.fields.user.firstName.label")}
                  name="firstName"
                  placeholder={t(
                    "pages.register.fields.user.firstName.placeholder"
                  )}
                  style={{ margin: 0 }}
                  value={form.user.firstName}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      user: { ...form.user, firstName: value },
                    })
                  }
                />
              </Col>

              <Col xs={24} md={12}>
                <Input
                  error={errors.user.lastName}
                  errorMessage={t("pages.register.fields.user.lastName.error")}
                  label={t("pages.register.fields.user.lastName.label")}
                  name="lastName"
                  placeholder={t(
                    "pages.register.fields.user.lastName.placeholder"
                  )}
                  style={{ margin: 0 }}
                  value={form.user.lastName}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      user: { ...form.user, lastName: value },
                    })
                  }
                />
              </Col>

              <Col xs={24} md={12}>
                <Input
                  error={errors.user.password}
                  errorMessage={
                    form.user.password !== ""
                      ? t("pages.register.fields.user.password.error")
                      : undefined
                  }
                  label={t("pages.register.fields.user.password.label")}
                  placeholder={t(
                    "pages.register.fields.user.password.placeholder"
                  )}
                  style={{ margin: 0 }}
                  type="password"
                  value={form.user.password}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      user: { ...form.user, password: value },
                    })
                  }
                />
              </Col>

              <Col xs={24} md={12}>
                <Input
                  error={errors.user.confirmationPassword}
                  errorMessage={
                    form.user.confirmationPassword !== ""
                      ? t(
                          "pages.register.fields.user.confirmationPassword.error"
                        )
                      : undefined
                  }
                  label={t(
                    "pages.register.fields.user.confirmationPassword.label"
                  )}
                  placeholder={t(
                    "pages.register.fields.user.confirmationPassword.placeholder"
                  )}
                  style={{ margin: 0 }}
                  type="password"
                  value={form.user.confirmationPassword}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      user: { ...form.user, confirmationPassword: value },
                    })
                  }
                />
              </Col>
            </Row>

            <br />
            <Typography.Title level={5}>
              {t("pages.register.fields.project.title")}
            </Typography.Title>

            <Input
              error={errors.project.name}
              errorMessage={t("pages.register.fields.project.name.error")}
              label={t("pages.register.fields.project.name.label")}
              placeholder={t("pages.register.fields.project.name.placeholder")}
              style={{ margin: 0 }}
              value={form.project.name}
              onChange={(value) =>
                setForm({
                  ...form,
                  project: { ...form.project, name: value },
                })
              }
            />
          </Form>

          <br />
          <Button
            loading={loading}
            style={{ display: "block", margin: "0 auto" }}
            type="primary"
            onClick={register}
          >
            {t("pages.register.action")}
          </Button>

          <Link to="/login">
            <Button style={{ display: "block", margin: "2em auto 0" }}>
              {t("pages.register.back")}
            </Button>
          </Link>
        </Card>
      </div>
    </PublicLayout>
  );
}
