import { Fragment } from "react";

// COMPONENTS
import { Spin, Typography } from "antd";

type Props = {
  children: React.ReactNode;
  spinning: boolean;
  data: unknown | undefined;
};

export default function Spinner(props: Props) {
  return props.spinning ? (
    <div style={{ padding: "1em 0" }}>
      <Spin style={{ width: "100%" }} />
    </div>
  ) : props.data === undefined ? (
    <Typography.Paragraph style={{ margin: 0 }}>No data</Typography.Paragraph>
  ) : (
    <Fragment>{props.children}</Fragment>
  );
}
