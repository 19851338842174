import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import projectService from "services/project";

// COMPONENTS
import { Button, Modal } from "antd";

type Props = {
  id: number;
  isVisible: boolean;
  projectName: string;
  userName: string;

  onClose: () => void;
  onDesactivate: () => void;
};

export default function DesactivatePopup(props: Props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  async function desactivate() {
    setLoading(true);

    await projectService.desactivate(props.id).then((response) => {
      if (response.success) {
        props.onDesactivate();
      }
    });

    setLoading(false);
  }

  return (
    <Modal
      centered
      footer={[
        <Button onClick={props.onClose}>{t("actions.cancel")}</Button>,
        <Button type="primary" danger loading={loading} onClick={desactivate}>
          {t("pages.parameters.tabs.project.user.actions.desactivate")}
        </Button>,
      ]}
      title={t("pages.parameters.tabs.project.user.modal.title", {
        name: props.userName,
      })}
      visible={props.isVisible}
      onCancel={props.onClose}
    >
      {t("pages.parameters.tabs.project.user.modal.description", {
        project: props.projectName,
      })}
    </Modal>
  );
}
