import { useTranslation } from "react-i18next";

// COMPONENTS
import { Form, Modal } from "antd";

import Input from "components/Input";

type NodeMailerCredentials = {
  host: string;
  port: string;
  user: string;
  pass: string;
};

type Props = {
  credentials: NodeMailerCredentials;
  showHelp: boolean;

  onChange: (credentials: NodeMailerCredentials) => void;
  onCloseHelp: () => void;
};

export default function Mailtrap(props: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Form layout="vertical">
        <div style={{ display: "flex", marginBottom: "1em" }}>
          <Input
            placeholder={t(
              "pages.configuration.tabs.email.parameters.nodemailer.fields.host.placeholder"
            )}
            style={{ margin: "0 1em 0 0", width: "100%" }}
            value={props.credentials.host}
            onChange={(value) =>
              props.onChange({ ...props.credentials, host: value })
            }
          />
          <Input
            placeholder={t(
              "pages.configuration.tabs.email.parameters.nodemailer.fields.port.placeholder"
            )}
            style={{ margin: 0, width: "100%" }}
            value={props.credentials.port}
            onChange={(value) =>
              props.onChange({ ...props.credentials, port: value })
            }
          />
        </div>

        <div style={{ display: "flex" }}>
          <Input
            placeholder={t(
              "pages.configuration.tabs.email.parameters.nodemailer.fields.email.placeholder"
            )}
            style={{ margin: "0 1em 0 0", width: "100%" }}
            type="email"
            value={props.credentials.user}
            onChange={(value) =>
              props.onChange({ ...props.credentials, user: value })
            }
          />
          <Input
            placeholder={t(
              "pages.configuration.tabs.email.parameters.nodemailer.fields.password.placeholder"
            )}
            style={{ margin: 0, width: "100%" }}
            type="password"
            value={props.credentials.pass}
            onChange={(value) =>
              props.onChange({ ...props.credentials, pass: value })
            }
          />
        </div>
      </Form>

      {/* Help */}
      <Modal
        centered
        footer={false}
        title={t(
          "pages.configuration.tabs.email.parameters.nodemailer.help.title"
        )}
        visible={props.showHelp}
        onCancel={props.onCloseHelp}
      >
        <a href="" target="_blank" rel="noreferrer">
          Nodemailer
        </a>

        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "pages.configuration.tabs.email.parameters.nodemailer.help.description"
            ),
          }}
        />
      </Modal>
    </div>
  );
}
