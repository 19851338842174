import { useState, useEffect } from "react";
import Clipboard from "react-clipboard.js";
import { useTranslation } from "react-i18next";
import { redirect } from "react-router-dom";

// SERVICES
import authService from "services/auth";
import projectService from "services/project";

// COMPONENTS
import { Alert, Button, Typography } from "antd";
import * as Icon from "@ant-design/icons";

import Spinner from "components/Spinner";

import Parameters from "../../Parameters";

export default function Api() {
  const { t } = useTranslation();

  const [apiKey, setApiKey] = useState<string | null>(null);
  const [fetching, setFetching] = useState(true);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showKey, setShowKey] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [copied]);

  async function fetchToken() {
    await projectService.getToken().then((response) => {
      if (response.success) {
        setApiKey(response.value);
      }
    });

    setFetching(false);
  }

  useEffect(() => {
    fetchToken();
  }, []);

  async function generate() {
    setLoading(true);

    await projectService.generateToken().then((response) => {
      if (response.success) {
        setApiKey(response.value);
        setShowKey(true);
      }
    });

    setLoading(false);
  }

  useEffect(() => {
    if (!authService.hasRank(["admin"])) {
      redirect("/parameters");
    }
  }, []);

  return (
    <Parameters
      pages={[
        {
          name: t("pages.parameters.title"),
          path: "/parameters",
        },
        {
          name: t("pages.parameters.navigation.project.items.api"),
          path: "/parameters/project-users",
        },
      ]}
      title={t("pages.parameters.navigation.project.items.api")}
    >
      <div>
        <Typography.Title level={5}>
          {t("pages.parameters.tabs.project.api.documentation.title")}
        </Typography.Title>

        <a
          href="https://app.swaggerhub.com/apis/nowtify.io/Nowtify/1.0.0-oas3"
          target="_blank"
          rel="noreferrer"
        >
          {t("pages.parameters.tabs.project.api.documentation.link")}
        </a>
      </div>
      <br />

      <div>
        <Typography.Title level={5}>
          {t("pages.parameters.tabs.project.api.apiKey.title")}
        </Typography.Title>

        {showKey && (
          <Alert
            action={
              <Clipboard
                data-clipboard-text={apiKey || ""}
                onClick={() => setCopied(true)}
              >
                <Button
                  icon={
                    !copied ? <Icon.CopyOutlined /> : <Icon.CheckOutlined />
                  }
                  size="small"
                />
              </Clipboard>
            }
            description={apiKey}
            message={t("pages.parameters.tabs.project.api.apiKey.warning")}
            style={{ marginBottom: "1em" }}
            type="warning"
          />
        )}

        <Spinner data={apiKey} spinning={fetching}>
          {apiKey === null ? (
            <div>
              <Typography.Paragraph>
                {t(
                  "pages.parameters.tabs.project.api.apiKey.hasNoKey.description"
                )}
              </Typography.Paragraph>
              <Button
                icon={<Icon.KeyOutlined />}
                loading={loading}
                type="primary"
                onClick={generate}
              >
                {t("pages.parameters.tabs.project.api.apiKey.hasNoKey.action")}
              </Button>
            </div>
          ) : (
            <div>
              <Typography.Paragraph>
                {t(
                  "pages.parameters.tabs.project.api.apiKey.hasKey.description"
                )}
              </Typography.Paragraph>
              <Button
                icon={<Icon.KeyOutlined />}
                loading={loading}
                type="primary"
                onClick={generate}
              >
                {t("pages.parameters.tabs.project.api.apiKey.hasKey.action")}
              </Button>
            </div>
          )}
        </Spinner>
      </div>
    </Parameters>
  );
}
