import { useState } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button, Form, message, Typography } from "antd";
import Input from "components/Input";

export default function Security() {
  const { t } = useTranslation();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    confirmationPassword: "",
    newPassword: "",
    oldPassword: "",
  });
  const [errors, setErrors] = useState({
    confirmationPassword: false,
    newPassword: false,
    oldPassword: false,
  });

  const fields: Array<"oldPassword" | "newPassword" | "confirmationPassword"> =
    ["oldPassword", "newPassword", "confirmationPassword"];

  async function save() {
    setLoading(true);
    setErrors({
      confirmationPassword: false,
      newPassword: false,
      oldPassword: false,
    });

    const formErrors = {
      confirmationPassword:
        form.newPassword !== "" &&
        form.newPassword !== form.confirmationPassword,
      newPassword: form.newPassword === "" || form.newPassword.length < 8,
      oldPassword: form.oldPassword === "",
    };
    setErrors(formErrors);
    if (
      !formErrors.confirmationPassword &&
      !formErrors.newPassword &&
      !formErrors.oldPassword
    ) {
      message.success(
        t("pages.parameters.tabs.account.security.password.success")
      );
      setEditing(false);
    }

    setLoading(false);
  }

  return (
    <div>
      <Typography.Title level={5}>
        {t("pages.parameters.tabs.account.security.password.title")}
      </Typography.Title>

      {!editing ? (
        <Button type="primary" onClick={() => setEditing(true)}>
          {t("actions.edit")}
        </Button>
      ) : (
        <Form layout="vertical">
          {fields.map((field) => (
            <Input
              key={field}
              error={errors[field]}
              errorMessage={
                form[field] !== ""
                  ? t(
                      `pages.parameters.tabs.account.security.password.fields.${field}.error`
                    )
                  : undefined
              }
              label={t(
                `pages.parameters.tabs.account.security.password.fields.${field}.label`
              )}
              placeholder={t(
                `pages.parameters.tabs.account.security.password.fields.${field}.placeholder`
              )}
              style={{ marginBottom: ".5em" }}
              type="password"
              value={form[field]}
              onChange={(value) => {
                setForm({ ...form, [field]: value });
                setErrors({ ...errors, [field]: false });
              }}
            />
          ))}

          <div
            style={{
              backgroundColor: "#fff",
              display: "flex",
              marginLeft: "auto",
              width: "fit-content",
            }}
          >
            <Button onClick={() => setEditing(false)}>
              {t("actions.cancel")}
            </Button>
            <Button
              loading={loading}
              type="primary"
              style={{ marginLeft: "1em" }}
              onClick={save}
            >
              {t("actions.edit")}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
}
