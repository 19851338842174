import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Button, Form, Typography } from "antd";
import { message } from "antd";

import Input from "components/Input";

import Parameters from "../../Parameters";

export default function Email() {
  const { t } = useTranslation();

  const user = authService.getAuthenticatedUser();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  function edit() {
    setLoading(true);
    setError(false);

    const emailError = email === "";
    setError(emailError);

    if (!emailError) {
      if (user !== null) {
        authService.setUser({ ...user, email });
        setEmail("");
        message.success(t("pages.parameters.tabs.account.email.edit.success"));
      }
    }

    setLoading(false);
  }

  return (
    <Parameters
      pages={[
        {
          name: t("pages.parameters.title"),
          path: "/parameters",
        },
        {
          name: t("pages.parameters.navigation.account.items.email"),
          path: "/parameters/account-email",
        },
      ]}
      title={t("pages.parameters.navigation.account.items.email")}
    >
      {user !== null && (
        <div>
          <div>
            <label style={{ fontWeight: 600 }}>
              {t("pages.parameters.tabs.account.email.currentEmail")}
            </label>
            <Typography.Paragraph>{user.email}</Typography.Paragraph>
          </div>

          {/* <Form layout="vertical">
            <Input
              error={error}
              errorMessage={t(
                "pages.parameters.tabs.account.email.edit.field.error"
              )}
              label={t("pages.parameters.tabs.account.email.edit.label")}
              placeholder={t(
                "pages.parameters.tabs.account.email.edit.field.placeholder"
              )}
              style={{ margin: 0 }}
              type="email"
              value={email}
              onChange={(value) => setEmail(value)}
            />

            <Button
              loading={loading}
              style={{ marginTop: ".5em" }}
              type="primary"
              onClick={edit}
            >
              {t("actions.edit")}
            </Button>
          </Form> */}
        </div>
      )}
    </Parameters>
  );
}
