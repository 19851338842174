const login = {
  action: "Connexion",
  fields: { email: "Email", password: "Mot de passe" },
  forgotPassword: "J'ai oublié mon mot de passe",
  errors: {
    accountNotChecked: "Vous n'avez pas confirmer votre email",
    "Internal error": "Une erreur est survenue",
    invitationNotAccepted:
      "Vous n'avez pas accepté l'invitation reçue par mail",
    wrongCredentials: "L'email ou le mot de passe est incorrect",
  },
  register: "S'inscrire",
  registerOk:
    "Votre compte a bien été créé. Un email vous a été envoyé afin de confirmer votre adresse email",
  title: "Connexion",
};

export default login;
