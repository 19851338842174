import { Fragment, useState } from "react";

// COMPONENTS
import { Button } from "antd";
import * as Icon from "@ant-design/icons";
import EditPopup from "./editButton/EditPopup";

type Props = {
  channel: ConfigurationSlack;

  onEdit: (channel: ConfigurationSlack) => void;
};

export default function EditButton(props: Props) {
  const [editing, setEditing] = useState(false);

  function edit(channel: ConfigurationSlack) {
    props.onEdit(channel);
    setEditing(false);
  }

  return (
    <Fragment>
      <Button
        type="primary"
        icon={<Icon.EditOutlined />}
        size="small"
        onClick={() => setEditing(true)}
      />

      <EditPopup
        channel={props.channel}
        isVisible={editing}
        onClose={() => setEditing(false)}
        onEdit={edit}
      />
    </Fragment>
  );
}
