import { useTranslation } from "react-i18next";

// COMPONENTS
import Parameters from "../../Parameters";
import Password from "./security/Password";

export default function Security() {
  const { t } = useTranslation();

  return (
    <Parameters
      pages={[
        {
          name: t("pages.parameters.title"),
          path: "/parameters",
        },
        {
          name: t("pages.parameters.navigation.account.items.security"),
          path: "/parameters/account-security",
        },
      ]}
      title={t("pages.parameters.navigation.account.items.security")}
    >
      <Password />
    </Parameters>
  );
}
