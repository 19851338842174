const invitation = {
  acceptedInvitation: {
    title: "L'invitation a été acceptée",
    description: "Vous pouvez dès à présent accéder au projet {{name}}",
    button: "Accéder au projet",
  },
  expiredToken: "Token expiré",
  register: {
    description:
      "Veuillez renseigner quelques informations supplémentaires afin de finaliser votre inscription.",
    form: {
      firstName: {
        label: "Prénom",
        placeholder: "Prénom",
        error: "Veuillez renseigner un prénom",
      },
      lastName: {
        label: "Nom",
        placeholder: "Nom",
        error: "Veuillez renseigner un nom",
      },
    },
  },
  title: "Invitation",
};

export default invitation;
