const resetPasswordRequest = {
  action: "Envoyer",
  back: "Retour à la page de connexion",
  confirmation: "Un email vous a été envoyé à l'adresse suivante : {{email}}",
  fields: {
    email: {
      label: "Email",
      placeholder: "Email",
      error: "Merci de renseigner une adresse email",
    },
  },
  title: "Mot de passe oublié",
  description:
    "Une email vous sera envoyé afin de réinitialiser votre mot de passe.",
};

export default resetPasswordRequest;
