import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import historyService from "services/history";

// COMPONENTS
import { Button, Form, message, Typography } from "antd";
import * as Icon from "@ant-design/icons";
import Input from "components/Input";

type Props = {
  historyId: number;
  error: (DataHistory & {
    status: Extract<DataHistory["status"], "error">;
  })["error"];

  onRedo: () => void;
};

export default function Redo(props: Props) {
  const { t } = useTranslation();

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Record<any, string>>({});
  const [errors, setErrors] = useState<Record<any, boolean>>({});

  async function chooseRedoAction() {
    switch (props.error.type) {
      case "bad_request":
      case "internal": {
        await redo();
        break;
      }
      case "missing_data": {
        for (const error of props.error.message) {
          setForm({ ...form, [error]: "" });
          setErrors({ ...errors, [error]: false });
        }

        setShowForm(true);
      }
    }
  }

  async function redo() {
    setLoading(true);

    for (const error of props.error.message) {
      setErrors({ ...errors, [error]: false });
    }

    let newErrors = {};

    for (const error of props.error.message) {
      newErrors = { ...newErrors, [error]: form[error] === "" };
    }

    setErrors(newErrors);

    if (Object.values(newErrors).every((x) => x === false)) {
      const body = form;

      await historyService
        .redo({ id: props.historyId, body })
        .then(async (response) => {
          if (response.success) {
            setShowForm(false);
            message.info(t("pages.history.modal.redo.form.success"));
            props.onRedo();
          }
        });
    }

    setLoading(false);
  }

  return (
    <Fragment>
      {!showForm ? (
        <Button
          icon={<Icon.ReloadOutlined />}
          loading={loading}
          onClick={chooseRedoAction}
        >
          {t("pages.history.modal.redo.action")}
        </Button>
      ) : (
        <Form layout="vertical">
          <Typography.Title level={5}>
            {t("pages.history.modal.redo.form.title")}
          </Typography.Title>

          {props.error.type === "missing_data" &&
            props.error.message.map((error) => (
              <Input
                label={error}
                placeholder={error}
                error={errors[error]}
                value={form[error]}
                onChange={(value) => setForm({ ...form, [error]: value })}
              />
            ))}

          <div>
            <Button
              style={{ marginRight: "1em" }}
              loading={loading}
              type="primary"
              onClick={redo}
            >
              {t("actions.save")}
            </Button>

            <Button onClick={() => setShowForm(false)}>
              {t("actions.cancel")}
            </Button>
          </div>
        </Form>
      )}
    </Fragment>
  );
}
