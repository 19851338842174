import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Tabs } from "antd";

import PrivateLayout from "layouts/PrivateLayout";

import Table from "./history/Table";

export default function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PrivateLayout title={t("pages.history.title")}>
      {authService.hasRank(["admin"]) ? (
        <Tabs
          defaultActiveKey={
            location.hash !== "" ? location.hash.split("#")[1] : "production"
          }
          onChange={(tab) => navigate(`/history#${tab}`)}
        >
          <Tabs.TabPane
            key="production"
            tab={t("pages.history.tabs.production")}
          >
            <Table type="production" />
          </Tabs.TabPane>
          <Tabs.TabPane key="test" tab={t("pages.history.tabs.test")}>
            <Table type="test" />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <Table type="production" />
      )}
    </PrivateLayout>
  );
}
