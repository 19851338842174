const parameters = {
  title: "Paramètres",
  navigation: {
    account: {
      title: "Compte",
      items: {
        email: "E-mail",
        informations: "Informations",
        security: "Sécurité",
      },
    },
    project: {
      title: "Projet {{name}}",

      items: {
        api: "API",
        informations: "Informations",
        users: "Utilisateurices",
      },
    },
  },
  tabs: {
    project: {
      informations: {
        name: {
          label: "Nom",
          placeholder: "Nom",
          error: "Le nom doit être renseigné",
        },
        logo: {
          label: "Logo du projet",
          placeholder: "Choisir un logo",
        },
      },
      api: {
        apiKey: {
          title: "Clé API",
          warning:
            "Veuillez à copier cette clé et à la garder, vous ne la reverrez plus.",
          hasKey: {
            action: "Regénérer la clé",
            description: "Si vous avez perdu la clé, vous pouvez la regénérer.",
          },
          hasNoKey: {
            action: "Générer une clé",
            description: "Vous n'avez pas encore générer de clé.",
          },
        },
        documentation: {
          title: "Documentation",
          link: "Lien vers la documentation",
        },
      },
      user: {
        actions: { desactivate: "Désactiver" },
        modal: {
          description:
            "En désactivant son compte, iel n'aura plus accès au projet {{project}}.",
          title: "Désactiver le compte de {{name}}",
        },
      },
      users: {
        actions: { invite: "Inviter" },
        modals: {
          invite: {
            title: "Inviter une nouvelle personne",
            fields: {
              email: {
                error: "Veuillez renseigner une adresse email",
                label: "Email",
                placeholder: "Email",
              },
              rank: { label: "Rôle" },
            },
          },
          reactivate: {
            description:
              "En Réactivant son compte, iel pourra à nouveau accéder au projet {{project}}.",
            title: "Réactiver le compte de {{name}}",
          },
        },
        table: {
          actions: {
            title: "Actions",
            cases: {
              reactivate: "Réactiver",
              seeDetails: "Voir les détails",
              sendInvitationAgain: "Renvoyer l'invitation",
            },
          },
          rank: "Rôle",
          status: {
            creator: "Créateurice",
            accepted_invitation: "Invitation acceptée",
            desactivated: "Désativé•e",
            pending_invitation: "Invitation en attente",
          },
          user: "Utilisateurice",
        },
        sendInvitationAgainMessage: "L'invitation a bien été renvoyée.",
      },
    },
    account: {
      email: {
        currentEmail: "Adresse mail actuelle",
        edit: {
          label: "Modifier l'adresse mail",
          field: {
            placeholder: "Nouvelle adresse mail",
            error: "Veuillez renseigner une adresse email",
          },
          success:
            "Votre addresse mail a bien été modifiée. Vous allez recevoir un email pour la confirmer.",
        },
      },
      informations: {
        fields: {
          firstName: { label: "Prénom", placeholder: "Prénom" },
          lastName: { label: "Nom", placeholder: "Nom" },
          profilePicture: { label: "Photo", placeholder: "Choisir une photo" },
        },
      },
      security: {
        password: {
          title: "Mot de passe",
          fields: {
            confirmationPassword: {
              label: "Confirmation du mot de passe",
              placeholder: "Confirmation du mot de passe",
              error:
                "La confirmation doit être identique au nouveau mot de passe",
            },
            newPassword: {
              label: "Nouveau mot de passe",
              placeholder: "Nouveau mot de passe",
              error: "Le mot de passe doit contenir au moins 8 caractères",
            },
            oldPassword: {
              label: "Mot de passe actuel",
              placeholder: "Mot de passe actuel",
              error: "Le mot de passe ne correspond pas",
            },
          },
          success: "Votre mot de passe a bien été modifié.",
        },
      },
    },
  },
};

export default parameters;
