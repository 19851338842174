import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Button, Card, Form, Typography } from "antd";

import Input from "components/Input";

import PublicLayout from "layouts/PublicLayout";

export default function ResetPasswordRequest() {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: false,
  });
  const [loading, setLoading] = useState(false);
  const [resetOk, setResetOk] = useState(false);

  async function register() {
    setLoading(true);
    setErrors({
      email: false,
    });

    const newErrors = {
      email: form.email === "",
    };
    setErrors(newErrors);

    if (!newErrors.email) {
      await authService.resetPassword(form).then((response) => {
        if (response.success) {
          setResetOk(true);
        }
      });
    }

    setLoading(false);
  }

  return (
    <PublicLayout>
      <div
        style={{ alignItems: "center", display: "flex", minHeight: "100vh" }}
      >
        <Card
          bordered={false}
          style={{
            margin: "auto",
            maxWidth: "500px",
            width: "50%",
          }}
        >
          <Typography.Title level={2} style={{ textAlign: "center" }}>
            {t("pages.resetPasswordRequest.title")}
          </Typography.Title>

          {!resetOk ? (
            <div>
              <Typography.Paragraph style={{ textAlign: "center" }}>
                {t("pages.resetPasswordRequest.description")}
              </Typography.Paragraph>

              <Form layout="vertical">
                <Input
                  error={errors.email}
                  errorMessage={t("pages.register.fields.user.email.error")}
                  label={t("pages.register.fields.user.email.label")}
                  placeholder={t(
                    "pages.register.fields.user.email.placeholder"
                  )}
                  style={{ margin: 0 }}
                  type="email"
                  value={form.email}
                  onChange={(value) => setForm({ ...form, email: value })}
                />
              </Form>

              <br />
              <Button
                loading={loading}
                style={{ display: "block", margin: "0 auto" }}
                type="primary"
                onClick={register}
              >
                {t("pages.resetPasswordRequest.action")}
              </Button>
            </div>
          ) : (
            <Typography.Paragraph style={{ textAlign: "center" }}>
              {t("pages.resetPasswordRequest.confirmation", {
                email: form.email,
              })}
            </Typography.Paragraph>
          )}
          <Link to="/login">
            <Button style={{ display: "block", margin: "2em auto 0" }}>
              {t("pages.resetPasswordRequest.back")}
            </Button>
          </Link>
        </Card>
      </div>
    </PublicLayout>
  );
}
