import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

// SERVICES
import authService from "services/auth";
import projectService from "services/project";

// COMPONENTS
import { Button, Typography } from "antd";

import SelectEdit from "components/SelectEdit";
import Spinner from "components/Spinner";
import UserAvatar from "components/UserAvatar";

import Parameters from "../../Parameters";
import DesactivatePopup from "./user/DesactivatePopup";

export default function User() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const currentUser = authService.getAuthenticatedUser();

  const [user, setUser] = useState<ProjectUser | undefined>(undefined);
  const [fetching, setFetching] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  async function fetchUser() {
    await projectService.getOneUser(parseInt(id || "")).then((response) => {
      if (response.success) {
        if (
          response.value.status === "desactivated" ||
          response.value.status === "pending_invitation"
        ) {
          navigate("/parameters/project-users");
        } else {
          setUser(response.value);
        }
      } else {
        if (response.message === "403") {
          navigate("/parameters/project-users");
        }
      }
    });

    setFetching(false);
  }

  useEffect(() => {
    fetchUser();
  }, []);

  function fullName() {
    return user !== undefined ? `${user.firstName} ${user.lastName}` : "...";
  }

  function desactivate() {
    navigate("/parameters/project-users");
  }

  async function save(value: string) {
    await projectService
      .updateUser({
        id: parseInt(id || ""),
        body: { rank: value },
      })
      .then((response) => {
        if (response.success) {
          setUser(response.value);
        }
      });
  }

  return (
    <Parameters
      pages={[
        {
          name: t("pages.parameters.title"),
          path: "/parameters",
        },
        {
          name: t("pages.parameters.navigation.project.items.users"),
          path: "/parameters/project-users",
        },
        {
          name: fullName(),
          path: `/parameters/project-users/${id}`,
        },
      ]}
      title={t("pages.parameters.navigation.project.items.users")}
    >
      <Button
        size="small"
        style={{ marginBottom: ".5em", padding: 0 }}
        type="link"
      >
        <Link to="/parameters/project-users">&lt; {t("actions.goBack")}</Link>
      </Button>

      <Spinner data={user} spinning={fetching}>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {user && (
              <UserAvatar
                user={user}
                size="large"
                style={{ marginRight: "1em" }}
              />
            )}
            <div>
              <Typography.Title level={5}>{fullName()}</Typography.Title>
              <Typography.Text>{user?.email}</Typography.Text>
              <br />
              <SelectEdit
                displayedValue={t(`ranks.${user?.rank}.label`)}
                editable={authService.hasRank(["admin"])}
                options={["admin", "writer"].map((rank) => ({
                  label: t(`ranks.${rank}.label`),
                  value: rank,
                }))}
                value={user?.rank}
                onSave={save}
              />
            </div>
          </div>

          <br />
          {authService.hasRank(["admin"]) &&
            currentUser?.id !== parseInt(id || "") && (
              <Button danger onClick={() => setShowPopup(true)}>
                {t("pages.parameters.tabs.project.user.actions.desactivate")}
              </Button>
            )}

          <DesactivatePopup
            id={parseInt(id || "")}
            isVisible={showPopup}
            projectName={currentUser !== null ? currentUser.project.name : ""}
            userName={fullName()}
            onClose={() => setShowPopup(false)}
            onDesactivate={desactivate}
          />
        </div>
      </Spinner>
    </Parameters>
  );
}
