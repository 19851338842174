import axios from "axios";

import { Result } from "../utils/Result";

import { apiUrl } from "index";

import { getHeaders, setUser, updateToken } from "./auth";

async function acceptInvitation(params: {
  token: string;
  body: { firstName?: string; lastName?: string };
}): Promise<Result<ProjectUser>> {
  return axios
    .post(`${apiUrl}/projects/invitation/${params.token}`, params.body)
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function activate(id: number): Promise<Result<ProjectUser>> {
  return axios
    .get(`${apiUrl}/projects/users/${id}/activate`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function checkInvitationToken(
  token: string
): Promise<
  Result<{ user: ProjectUser; project: Omit<Project, "parameters" | "users"> }>
> {
  return axios
    .get(`${apiUrl}/projects/invitation/${token}`)
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function create(body: {
  name: string;
}): Promise<Result<{ user: AuthenticatedUser; token: string }>> {
  return axios
    .post(`${apiUrl}/projects`, body, getHeaders())
    .then((response) => {
      const { token, user } = response.data;
      setUser(user);
      updateToken(token);

      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function desactivate(id: number): Promise<Result<ProjectUser>> {
  return axios
    .get(`${apiUrl}/projects/users/${id}/desactivate`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function generateToken(): Promise<Result<string>> {
  return axios
    .put(`${apiUrl}/projects/token`, {}, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

async function getOne(): Promise<Result<Project>> {
  return axios
    .get(`${apiUrl}/projects`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

async function getOneUser(id: number): Promise<Result<ProjectUser>> {
  return axios
    .get(`${apiUrl}/projects/users/${id}`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function getParameters(): Promise<Result<ProjectParameter>> {
  return axios
    .get(`${apiUrl}/projects/parameters`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

async function getToken(): Promise<Result<string | null>> {
  return axios
    .get(`${apiUrl}/projects/token`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

async function invite(body: {
  email: string;
  rank: Rank;
}): Promise<Result<ProjectUser>> {
  return axios
    .post(`${apiUrl}/projects/users`, body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function sendInvitationAgain(
  userId: number
): Promise<Result<ProjectUser>> {
  return axios
    .get(`${apiUrl}/projects/users/${userId}/resend`, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function switchProject(params: {
  projectId: number;
}): Promise<Result<{ user: AuthenticatedUser; token: string }>> {
  return axios
    .get(`${apiUrl}/projects/${params.projectId}`, getHeaders())
    .then(async (response) => {
      const { token, user } = response.data;
      updateToken(token);

      const parametersResponse = await getParameters();

      if (parametersResponse.success) {
        setUser({
          ...user,
          project: { ...user.project, parameters: parametersResponse.value },
        });
      } else {
        setUser(user);
      }

      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function update(body: { name: string }): Promise<Result<Project>> {
  return axios
    .put(`${apiUrl}/projects`, body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

async function updateLogo(body: FormData): Promise<Result<Project>> {
  return axios
    .post(`${apiUrl}/projects/logo`, body, {
      headers: {
        ...getHeaders().headers,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

async function updateParameterLogo(
  body: FormData
): Promise<Result<ProjectParameter>> {
  return axios
    .post(`${apiUrl}/projects/parameters`, body, {
      headers: {
        ...getHeaders().headers,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

async function updateParameters(body: {
  name?: string | null;
  signature?: string | null;
  sendingEmail?: string | null;
}): Promise<Result<ProjectParameter>> {
  return axios
    .put(`${apiUrl}/projects/parameters`, body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

async function updateUser(params: {
  id: number;
  body: { rank?: string };
}): Promise<Result<ProjectUser>> {
  return axios
    .put(`${apiUrl}/projects/users/${params.id}`, params.body, getHeaders())
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

const projectService = {
  acceptInvitation,
  activate,
  checkInvitationToken,
  create,
  desactivate,
  generateToken,
  getOne,
  getOneUser,
  getParameters,
  getToken,
  invite,
  sendInvitationAgain,
  switchProject,
  update,
  updateLogo,
  updateParameterLogo,
  updateParameters,
  updateUser,
};
export default projectService;
