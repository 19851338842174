import React from "react";

// COMPONENTS
import { Select } from "antd";

type Props = {
  disabled?: boolean;
  options: Array<{
    label: string | React.ReactChild;
    value: string;
  }>;
  placeholder?: string;
  width?: string;
  onChange: (value: any) => void;
} & (
  | { isMultiple: true; value: Array<any> }
  | { isMultiple: false; value: any }
);

export default function MySelect(props: Props) {
  function handleSelect(value: string) {
    if (props.isMultiple) {
      const list = props.value;
      list.push(value);
      props.onChange(list);
    } else {
      props.onChange(value);
    }
  }

  function handleDeselect(value: string) {
    if (props.isMultiple) {
      const list = props.value.filter((item) => item !== value);
      props.onChange(list);
    } else {
      props.onChange(undefined);
    }
  }

  return (
    <Select
      allowClear={props.isMultiple}
      disabled={props.disabled || false}
      mode={props.isMultiple ? "multiple" : undefined}
      placeholder={props.placeholder}
      style={{ width: props.width }}
      value={props.value}
      onSelect={(value: string) => handleSelect(value)}
      onDeselect={(value: string) => handleDeselect(value)}
    >
      {props.options.map((option, key) => (
        <Select.Option key={key} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
}
