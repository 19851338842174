import actions from "./actions";
import configuration from "./configuration";
import confirmEmail from "./confirmEmail";
import dashboard from "./dashboard";
import history from "./history";
import invitation from "./invitation";
import login from "./login";
import newProject from "./newProject";
import parameters from "./parameters";
import register from "./register";
import resetPasswordConfirm from "./resetPasswordConfirm";
import resetPasswordRequest from "./resetPasswordRequest";
import templates from "./templates";

const pages = {
  actions,
  configuration,
  confirmEmail,
  dashboard,
  history,
  invitation,
  login,
  newProject,
  parameters,
  register,
  resetPasswordConfirm,
  resetPasswordRequest,
  templates,
};

export default pages;
