import React from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Layout } from "antd";

import PrivateLayout from "layouts/PrivateLayout";

import Content from "./parameters/Content";
import Navigation from "./parameters/Navigation";

type Props = {
  children: React.ReactNode;
  pages: Array<{ name: string; path: string }>;
  title: string;
};

export default function Parameters(props: Props) {
  const { t } = useTranslation();

  const user = authService.getAuthenticatedUser();

  return (
    <PrivateLayout title={t("pages.parameters.title")}>
      {user !== null && (
        <Layout>
          <Navigation user={user} />

          <Content pages={props.pages} title={props.title}>
            {props.children}
          </Content>
        </Layout>
      )}
    </PrivateLayout>
  );
}
