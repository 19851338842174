import pages from "./pages";

const fr = {
  actions: {
    cancel: "Annuler",
    chooseImage: "Choisir une image",
    close: "Fermer",
    delete: "Supprimer",
    edit: "Modifier",
    goBack: "Retour",
    save: "Enregistrer",
  },
  form: {
    defaultErrorMessage: "Veuillez renseigner ce champ",
    undefined: "Non renseigné",
  },
  layout: {
    private: {
      navigation: {
        actions: "Actions",
        configuration: "Configuration",
        dashboard: "Dashboard",
        history: "Historique",
        templates: "Templates",
        submenu: {
          addProject: "Ajouter un nouveau projet",
          logout: "Déconnexion",
          parameters: "Paramètres",
        },
      },
    },
  },
  pages,
  ranks: {
    admin: {
      label: "Administrateurice",
      description: "Peut tout faire.",
    },
    writer: {
      label: "Rédacteurice",
      description:
        "Peut accéder à l'historique et éditer le contenu des templates.",
    },
  },
  channels: {
    email: "Email",
    slack: "Slack",
    sms: "Sms",
  },
};

export default fr;
