import { useTranslation } from "react-i18next";

// COMPONENTS
import { Form, Modal } from "antd";

import Input from "components/Input";

type SendinblueCredentials = { key: string };

type Props = {
  credentials: SendinblueCredentials;
  showHelp: boolean;

  onChange: (credentials: SendinblueCredentials) => void;
  onCloseHelp: () => void;
};

export default function Mailtrap(props: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Form layout="vertical">
        <div style={{ display: "flex" }}>
          <Input
            placeholder={t(
              "pages.configuration.tabs.email.parameters.sendinblue.fields.key.placeholder"
            )}
            style={{ margin: "0 1em 0 0", width: "100%" }}
            value={props.credentials.key}
            onChange={(value) =>
              props.onChange({ ...props.credentials, key: value })
            }
          />
        </div>
      </Form>

      {/* Help */}
      <Modal
        centered
        footer={false}
        title={t(
          "pages.configuration.tabs.email.parameters.sendinblue.help.title"
        )}
        visible={props.showHelp}
        onCancel={props.onCloseHelp}
      >
        <a href="https://sendinblue.com/" target="_blank" rel="noreferrer">
          Sendinblue
        </a>

        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "pages.configuration.tabs.email.parameters.sendinblue.help.description"
            ),
          }}
        />
      </Modal>
    </div>
  );
}
