// UTILS
import { firstLetter } from "utils/texts";

// COMPONENTS
import { Avatar, AvatarProps } from "antd";

type Props = {
  size?: AvatarProps["size"];
  style?: React.CSSProperties;
  user: User;
};

export default function UserAvatar(props: Props) {
  return (
    <Avatar
      size={props.size || "default"}
      src={props.user.profilePicture}
      style={props.style}
    >
      {props.user.firstName !== null ? firstLetter(props.user.firstName) : ""}
      {props.user.lastName !== null ? firstLetter(props.user.lastName) : ""}
    </Avatar>
  );
}
