import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button, Form, Typography } from "antd";
import * as Icon from "@ant-design/icons";

import Input from "./Input";

type Props = {
  error?: boolean;
  errorMessage?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  type?: "email" | "password" | "text" | "textarea";
  value: string | null;

  onSave: (value: string | null) => void;
};

export default function InputEdit(props: Props) {
  const { t } = useTranslation();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(props.value !== null ? props.value : "");

  useEffect(() => {
    setValue(props.value !== null ? props.value : "");
    setLoading(false);
    setEditing(false);
  }, [props.value]);

  function save() {
    setLoading(true);
    props.onSave(value === "" ? null : value);
  }

  return (
    <div style={props.style}>
      {props.label && <label style={{ fontWeight: 600 }}>{props.label}</label>}

      {!editing ? (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography.Paragraph style={{ margin: 0 }}>
              {props.value !== null ? props.value : t("form.undefined")}
            </Typography.Paragraph>

            <Button
              icon={<Icon.EditOutlined />}
              type="text"
              onClick={() => {
                setEditing(true);
                setValue(props.value !== null ? props.value : "");
              }}
            />
          </div>
        </div>
      ) : (
        <Form layout="vertical">
          <Input
            placeholder={props.placeholder}
            prefix={
              <Icon.CloseOutlined
                style={{ cursor: "pointer" }}
                onClick={() => setEditing(false)}
              />
            }
            suffix={
              !loading ? (
                <Icon.SaveOutlined
                  style={{ cursor: "pointer" }}
                  onClick={save}
                />
              ) : (
                <Icon.LoadingOutlined />
              )
            }
            value={value}
            type={props.type}
            onChange={(value) => setValue(value)}
          />

          {props.type === "textarea" && (
            <div>
              <Button
                loading={loading}
                style={{ marginRight: "1em" }}
                type="primary"
                onClick={save}
              >
                {t("actions.save")}
              </Button>
              <Button onClick={() => setEditing(false)}>
                {t("actions.cancel")}
              </Button>
            </div>
          )}
        </Form>
      )}
    </div>
  );
}
