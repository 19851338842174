import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { redirect, useNavigate, useLocation } from "react-router-dom";

// SERVICES
import authService from "services/auth";

// COMPONENTS
import { Tabs, Typography } from "antd";
import * as Icon from "@ant-design/icons";

import PrivateLayout from "layouts/PrivateLayout";

import Email from "./configuration/Email";
import Slack from "./configuration/Slack";

export default function Configuration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("email");

  useEffect(() => {
    setActiveTab(defineActiveTab());
  }, [location]);

  function defineActiveTab() {
    if (location.hash === "") {
      setActiveTab("email");
    }
    const hash = location.hash.split("#")[1];

    switch (hash) {
      case "email":
      case "slack":
        return hash;
      case "sms":
      default:
        return "email";
    }
  }

  const tabs: Array<{
    content: React.ReactChild;
    disabled: boolean;
    icon: React.ReactChild;
    key: string;
    name: string;
  }> = [
    {
      content: <Email />,
      disabled: false,
      icon: <Icon.MailOutlined />,
      key: "email",
      name: t("channels.email"),
    },
    {
      content: <Slack />,
      disabled: false,
      icon: <Icon.SlackOutlined />,
      key: "slack",
      name: t("channels.slack"),
    },
    {
      content: <div />,
      disabled: true,
      icon: <Icon.MobileOutlined />,
      key: "sms",
      name: t("channels.sms"),
    },
  ];

  useEffect(() => {
    if (!authService.hasRank(["admin"])) {
      redirect("/dashboard");
    }
  }, []);

  return (
    <PrivateLayout title={t("pages.configuration.title")}>
      <div className="tab-container">
        <Tabs
          activeKey={activeTab}
          style={{ marginBottom: 0 }}
          type="card"
          onChange={(activeKey) => navigate(`/configuration#${activeKey}`)}
        >
          {tabs.map((tab) => (
            <Tabs.TabPane
              disabled={tab.disabled}
              key={tab.key}
              style={{ padding: "2em" }}
              tab={
                <span>
                  {tab.icon}
                  {tab.name}
                </span>
              }
            >
              <Typography.Title level={4}>
                {t(`pages.configuration.tabs.${tab.key}.title`)}
              </Typography.Title>

              {tab.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </PrivateLayout>
  );
}
