import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// SERVICES
import projectService from "services/project";

// COMPONENTS
import AddProject from "./projects/AddProject";
import CurrentProject from "./projects/CurrentProject";
import OtherProject from "./projects/OtherProject";

type Props = { user: AuthenticatedUser };

export default function Projects(props: Props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(0);

  async function switchProject(id: number) {
    setLoading(id);

    await projectService.switchProject({ projectId: id }).then((response) => {
      if (response.success) {
        navigate(0);
      }
    });
  }

  return (
    <div>
      <CurrentProject project={props.user.project} />

      {props.user.projects
        .filter((project) => project.id !== props.user.project.id)
        .map((project) => (
          <OtherProject
            key={project.id}
            loading={loading === project.id}
            project={project}
            onSwitch={switchProject}
          />
        ))}

      <AddProject />
    </div>
  );
}
