import { useTranslation } from "react-i18next";

// COMPONENTS
import PrivateLayout from "layouts/PrivateLayout";

export default function Dashboard() {
  const { t } = useTranslation();

  return (
    <PrivateLayout title={t("pages.dashboard.title")}>
      <p>Bienvenue</p>
    </PrivateLayout>
  );
}
