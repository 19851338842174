import { useState } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button, Typography } from "antd";

import Form from "./parameters/Form";
import Mailtrap from "./display/Mailtrap";
import Nodemailer from "./display/Nodemailer";
import Sendinblue from "./display/Sendinblue";
import { config } from "process";

type Props = {
  configuration: ConfigurationEmail;
  options: Array<{
    label: string;
    logo: string;
    value: "mailtrap" | "sendinblue" | "nodemailer";
    defaultValue: any;
  }>;
  title: string;
  type: "production" | "test";
};

export default function Parameters(props: Props) {
  const { t } = useTranslation();

  const [configuration, setConfiguration] = useState(props.configuration);
  const [editing, setEditing] = useState(false);

  function isNull() {
    switch (configuration.type) {
      case "test": {
        switch (configuration.service) {
          case "mailtrap": {
            if (
              configuration.credentials.pass === "" &&
              configuration.credentials.user === ""
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
        break;
      }
      case "production": {
        switch (configuration.service) {
          case "sendinblue": {
            if (configuration.credentials.key === "") {
              return true;
            } else {
              return false;
            }
          }
          case "nodemailer": {
            if (
              configuration.credentials.host === "" &&
              configuration.credentials.port === "" &&
              configuration.credentials.user === "" &&
              configuration.credentials.pass === ""
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
  }

  function getOption() {
    if (configuration === null) return null;
    if (configuration.service === undefined) return null;
    const option = props.options.find(
      (item) => item.value === configuration.service
    );
    if (option === undefined) return null;
    return option;
  }

  function save(credentials: ConfigurationEmail) {
    setConfiguration(credentials);
    setEditing(false);
  }

  return (
    <div>
      <Typography.Title level={5}>{props.title}</Typography.Title>

      {!editing ? (
        <div>
          {isNull() ? (
            <Typography.Paragraph>
              {t(
                "pages.configuration.tabs.email.parameters.hasNoConfiguration.description"
              )}
            </Typography.Paragraph>
          ) : (
            <div>
              {getOption() !== null && (
                <p>
                  <img
                    alt={getOption()?.value}
                    src={getOption()?.logo}
                    style={{ marginRight: "5px" }}
                    width="20px"
                  />{" "}
                  <strong>{getOption()?.label}</strong>
                </p>
              )}
              {(() => {
                switch (configuration.service) {
                  case "mailtrap":
                    return <Mailtrap credentials={configuration.credentials} />;
                  case "nodemailer":
                    return (
                      <Nodemailer credentials={configuration.credentials} />
                    );
                  case "sendinblue":
                    return (
                      <Sendinblue credentials={configuration.credentials} />
                    );
                }
              })()}
            </div>
          )}

          <Button type="primary" onClick={() => setEditing(true)}>
            {t(
              `pages.configuration.tabs.email.parameters.${
                configuration === null
                  ? "hasNoConfiguration"
                  : "hasConfiguration"
              }.action`
            )}
          </Button>
        </div>
      ) : (
        <Form
          options={props.options}
          parameters={configuration}
          onCancel={() => setEditing(false)}
          onUpdate={save}
        />
      )}
    </div>
  );
}
