import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button } from "antd";

import AddPopup from "./addButton/AddPopup";

type Props = {
  onAdd: (template: Template) => void;
};

export default function AddButton(props: Props) {
  const { t } = useTranslation();

  const [adding, setAdding] = useState(false);

  function add(template: Template) {
    props.onAdd(template);
    setAdding(false);
  }

  return (
    <Fragment>
      <Button
        style={{ display: "block", marginLeft: "auto" }}
        type="primary"
        onClick={() => setAdding(true)}
      >
        {t("pages.templates.actions.add")}
      </Button>

      <AddPopup
        isVisible={adding}
        onAdd={add}
        onClose={() => setAdding(false)}
      />
    </Fragment>
  );
}
