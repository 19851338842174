import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// SERVICES
import actionService from "services/action";

// COMPONENTS
import { Button, Space, Spin, Table } from "antd";
import * as Icon from "@ant-design/icons";

import PrivateLayout from "layouts/PrivateLayout";

import ActionPopup from "./actions/ActionPopup";
import AddButton from "./actions/AddButton";
import RemoveButton from "./actions/RemoveButton";

export default function Actions() {
  const { t } = useTranslation();

  const [modalData, setModalData] = useState<Action | null>(null);
  const [actions, setActions] = useState<Array<Action>>([]);
  const [fetching, setFetching] = useState(true);

  async function fetchActions() {
    await actionService.getAll().then((response) => {
      if (response.success) {
        setActions(response.value);
      }
    });

    setFetching(false);
  }

  useEffect(() => {
    fetchActions();
  }, []);

  function remove(id: number) {
    const newActions = actions.filter((item) => item.id !== id);
    setActions(newActions);
  }

  const columns = [
    {
      align: "center" as const,
      dataIndex: "id",
      key: "id",
      title: t("pages.actions.table.id"),
    },
    {
      align: "center" as const,
      dataIndex: "name",
      key: "name",
      title: t("pages.actions.table.name"),
    },
    {
      align: "center" as const,
      dataIndex: "channel",
      key: "channel",
      title: t("pages.actions.table.channel"),
      render: (channel: Channel) => t(`channels.${channel}`),
    },
    {
      align: "center" as const,
      dataIndex: "template",
      key: "template",
      title: t("pages.actions.table.template"),
      render: (template: Template) => (
        <Link to={`/templates#${template.id}`}>{template.name}</Link>
      ),
    },
    {
      align: "center" as const,
      key: "actions",
      title: t("pages.actions.table.actions"),
      render: (record: Action) => (
        <Space size="middle">
          <Button
            icon={<Icon.EyeOutlined />}
            size="small"
            type="primary"
            onClick={() => setModalData(record)}
          />
          <RemoveButton action={record} onRemove={() => remove(record.id)} />
        </Space>
      ),
    },
  ];

  function add(action: Action) {
    setActions([...actions, action]);
  }

  return (
    <PrivateLayout title={t("pages.actions.title")}>
      <AddButton onAdd={add} />

      <Spin spinning={fetching}>
        <Table columns={columns} dataSource={actions} />
      </Spin>

      {modalData !== null && (
        <ActionPopup action={modalData} onClose={() => setModalData(null)} />
      )}
    </PrivateLayout>
  );
}
