import { useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import templateService from "services/template";

// COMPONENTS
import { Button, Form, Modal } from "antd";

import Input from "components/Input";
import Select from "components/Select";

type Props = {
  isVisible: boolean;

  onAdd: (template: Template) => void;
  onClose: () => void;
};

export default function AddPopup(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState<{
    channel: Channel;
    name: string;
    description: string;
  }>({
    channel: "email",
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ name: false });

  async function save() {
    setLoading(true);
    setErrors({ name: false });

    const newErrors = {
      name: form.name === "",
    };

    setErrors(newErrors);

    if (!newErrors.name) {
      const body = {
        channel: form.channel,
        name: form.name,
        description: form.description === "" ? null : form.description,
      };

      await templateService.create(body).then((response) => {
        if (response.success) {
          props.onAdd(response.value);
        }
      });
    }

    setLoading(false);
  }

  return (
    <Modal
      footer={[
        <Button onClick={() => props.onClose()}>{t("actions.cancel")}</Button>,
        <Button loading={loading} type="primary" onClick={save}>
          {t("actions.save")}
        </Button>,
      ]}
      title={t("pages.templates.modal.title")}
      visible={props.isVisible}
      onCancel={props.onClose}
    >
      <Form layout="vertical">
        <label style={{ display: "block", marginBottom: "8px" }}>
          {t("pages.templates.modal.fields.channel.label")}
        </label>
        <Select
          isMultiple={false}
          options={[
            {
              label: t("channels.email").toString(),
              value: "email",
            },
            {
              label: t("channels.slack").toString(),
              value: "slack",
            },
            {
              label: t("channels.sms").toString(),
              value: "sms",
            },
          ]}
          placeholder={t("pages.templates.modal.fields.channel.placeholder")}
          value={form.channel}
          width="100%"
          onChange={(value) => setForm({ ...form, channel: value })}
        />

        <br />
        <br />

        <Input
          error={errors.name}
          label={t("pages.templates.modal.fields.name.label")}
          placeholder={t("pages.templates.modal.fields.name.placeholder")}
          value={form.name}
          onChange={(value) => setForm({ ...form, name: value })}
        />
        <Input
          label={t("pages.templates.modal.fields.description.label")}
          placeholder={t(
            "pages.templates.modal.fields.description.placeholder"
          )}
          type="textarea"
          value={form.description}
          onChange={(value) => setForm({ ...form, description: value })}
        />
      </Form>
    </Modal>
  );
}
