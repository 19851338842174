import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// SERVICES
import projectService from "services/project";

// COMPONENTS
import { Col, Row } from "antd";

import FileEdit from "components/FileEdit";
import InputEdit from "components/InputEdit";
import Spinner from "components/Spinner";

import Parameters from "../../Parameters";
import authService from "services/auth";

export default function Informations() {
  const { t } = useTranslation();

  const [project, setProject] = useState<Project | undefined>(undefined);
  const [fetching, setFetching] = useState(true);
  const [errors, setErrors] = useState({ name: false });

  async function fetchProject() {
    await projectService.getOne().then((response) => {
      if (response.success) {
        setProject(response.value);
      }
    });

    setFetching(false);
  }

  useEffect(() => {
    fetchProject();
  }, []);

  async function save(value: string | null) {
    setErrors({ name: false });

    const newErrors = {
      name: value === null,
    };
    setErrors(newErrors);

    if (!newErrors.name) {
      const body = { name: value === null ? "" : value };
      await projectService.update(body).then((response) => {
        if (response.success) {
          const user = authService.getAuthenticatedUser();
          if (user !== null) {
            authService.setUser({ ...user, project: response.value });
          }
          setProject(response.value);
        }
      });
    }
  }

  async function saveFile(formData: FormData) {
    await projectService.updateLogo(formData).then((response) => {
      if (response.success) {
        const user = authService.getAuthenticatedUser();
        if (user !== null) {
          authService.setUser({ ...user, project: response.value });
        }
        setProject(response.value);
      }
    });
  }

  return (
    <Parameters
      pages={[
        {
          name: t("pages.parameters.title"),
          path: "/parameters",
        },
        {
          name: t("pages.parameters.navigation.project.items.informations"),
          path: "/parameters/project-informations",
        },
      ]}
      title={t("pages.parameters.navigation.project.items.informations")}
    >
      <Spinner data={project} spinning={fetching}>
        {project && (
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12}>
              <InputEdit
                error={errors.name}
                errorMessage={t(
                  "pages.parameters.tabs.project.informations.name.error"
                )}
                label={t(
                  "pages.parameters.tabs.project.informations.name.label"
                )}
                name="name"
                placeholder={t(
                  "pages.parameters.tabs.project.informations.name.placeholder"
                )}
                value={project.name}
                onSave={save}
              />
            </Col>

            <Col xs={24} sm={12}>
              <FileEdit
                label={t(
                  "pages.parameters.tabs.project.informations.logo.label"
                )}
                placeholder={t(
                  "pages.parameters.tabs.project.informations.logo.placeholder"
                )}
                file={project.logo}
                width="100px"
                onSave={saveFile}
              />
            </Col>
          </Row>
        )}
      </Spinner>
    </Parameters>
  );
}
