import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// SERVICES
import userService from "services/user";

// COMPONENTS
import { Button, Typography } from "antd";

import Spinner from "components/Spinner";

export default function ConfirmEmail() {
  const { t } = useTranslation();

  const { token } = useParams<{ token: string }>();

  const [fetching, setFetching] = useState(true);
  const [isTokenOk, setIsTokenOk] = useState(false);

  async function checkToken() {
    await userService.checkEmailToken(token || "").then((response) => {
      setIsTokenOk(response.success);
    });

    setFetching(false);
  }

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        margin: "0 auto",
        padding: "2em",
        width: "50%",
      }}
    >
      <Spinner spinning={fetching} data={{}}>
        <Typography.Title level={3}>
          {t("pages.confirmEmail.title")}
        </Typography.Title>

        {!isTokenOk ? (
          <Typography.Paragraph>
            {t("pages.confirmEmail.expiredToken")}
          </Typography.Paragraph>
        ) : (
          <div>
            <Typography.Title level={5}>
              {t("pages.confirmEmail.acceptedInvitation.title")}
            </Typography.Title>
            <Typography.Paragraph>
              {t("pages.confirmEmail.acceptedInvitation.description")}
            </Typography.Paragraph>

            <Link to="/">
              <Button type="primary">
                {t("pages.confirmEmail.acceptedInvitation.button")}
              </Button>
            </Link>
          </div>
        )}
      </Spinner>
    </div>
  );
}
