import axios from "axios";
import jwtDecode from "jwt-decode";

import { Result } from "../utils/Result";

import projectService from "./project";

import { apiUrl } from "index";

export function getHeaders() {
  const token = window.localStorage.getItem("authToken");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
}

function isAuthenticated() {
  const token = window.localStorage.getItem("authToken");
  // 2. Si le token est encore valide
  if (token) {
    const { exp: expiration }: any = jwtDecode(token);
    if (expiration * 1000 > new Date().getTime()) {
      return true;
    }
    return false;
  }
  return false;
}

function setAxiosToken(token: string) {
  axios.interceptors.request.use(
    async (config) => {
      if (isAuthenticated()) {
        config.headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        };
        return config;
      } else {
        logout();
        window.open("/", "_self");
      }
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

function getAuthenticatedUser(): AuthenticatedUser | null {
  const user = window.localStorage.getItem("user");

  if (isAuthenticated()) {
    if (user !== null) {
      return JSON.parse(user);
    }
  }

  return null;
}
function hasRank(ranks: Array<Rank>): boolean {
  const user = getAuthenticatedUser();
  if (user === null) return false;
  const userInProject = user.project.users.find((item) => item.id === user.id);
  if (userInProject === undefined) return false;
  if (ranks.includes(userInProject.rank)) {
    return true;
  } else {
    return false;
  }
}

async function login(credentials: {
  email: string;
  password: string;
}): Promise<Result<AuthenticatedUser>> {
  return axios
    .post(`${apiUrl}/login`, credentials)
    .then(async (response) => {
      const { token, user } = response.data;
      updateToken(token);

      const parametersResponse = await projectService.getParameters();

      if (parametersResponse.success) {
        setUser({
          ...user,
          project: { ...user.project, parameters: parametersResponse.value },
        });
      } else {
        setUser(user);
      }

      return { success: true as const, value: user };
    })
    .catch((error) => {
      return { success: false, message: error.response.data };
    });
}

function logout(): void {
  // @ts-ignore
  delete axios.defaults.headers["Authorization"];
  window.localStorage.removeItem("authToken");
  window.localStorage.removeItem("user");
}

async function register(body: {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmationPassword: string;
  };
  project: {
    name: string;
  };
}): Promise<Result<void>> {
  return axios
    .post(`${apiUrl}/register`, body)
    .then(() => {
      return { success: true as const, value: undefined };
    })
    .catch((error) => {
      return { success: false, message: error.response.data };
    });
}

async function resetPassword(body: { email: string }): Promise<Result<void>> {
  return axios
    .post(`${apiUrl}/reset-password`, body)
    .then(() => {
      return { success: true as const, value: undefined };
    })
    .catch((error) => {
      return { success: false, message: error.response.data };
    });
}

export function updateToken(token: string) {
  // // Je stock mon token dans le local storage
  window.localStorage.setItem("authToken", token);

  // //On prévient Axios qu'on  a maintenant un header par défaut sur toutes nos futures requêtes HTTP
  setAxiosToken(token);
}

export function setUser(user: AuthenticatedUser): void {
  window.localStorage.setItem("user", JSON.stringify(user));
}

const authService = {
  getAuthenticatedUser,
  isAuthenticated,
  hasRank,
  login,
  logout,
  register,
  resetPassword,
  setUser,
};
export default authService;
