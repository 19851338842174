import axios from "axios";

import { Result } from "../utils/Result";

import { apiUrl } from "index";
import { getHeaders } from "./auth";

async function checkEmailToken(token: string): Promise<Result<void>> {
  return axios
    .get(`${apiUrl}/users/email/${token}`)
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function checkPasswordToken(token: string): Promise<Result<void>> {
  return axios
    .get(`${apiUrl}/users/password/${token}`)
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function confirmResetPassword(params: {
  token: string;
  body: { password: string };
}): Promise<Result<void>> {
  return axios
    .post(`${apiUrl}/users/password/${params.token}`, params.body)
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function update(body: {
  firstName?: string;
  lastName?: string;
}): Promise<Result<User>> {
  return axios
    .put(`${apiUrl}/users`, body)
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch((error) => {
      return { success: false, message: error.response.status.toString() };
    });
}

async function updateProfilePicture(body: FormData): Promise<Result<User>> {
  return axios
    .post(`${apiUrl}/users/profile-picture`, body, {
      headers: {
        ...getHeaders().headers,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return { success: true as const, value: response.data };
    })
    .catch(() => {
      return { success: false, message: "" };
    });
}

const userService = {
  checkEmailToken,
  checkPasswordToken,
  confirmResetPassword,
  update,updateProfilePicture
};
export default userService;
