const history = {
  tabs: { production: "Production", test: "Test" },
  modal: {
    data: {
      createdAt: "Créée le",
      lastUpdate: "Dernière mis à jour le",
    },
    redo: {
      action: "Relancer",
      form: {
        title: "Renseigner les informations manquantes",
        success: "La notification a bien été renvoyée",
      },
    },
    title: "Notification #{{id}}",
    errors: {
      bad_request: "Mauvaise requête",
      internal:
        "Erreur interne. Malheureusement vous ne pouvez rien faire de plus, mise à part relancer.",
      missing_data: "Il manque les informations suivantes dans le contenu :",
    },
  },
  title: "Historique",
  table: {
    actions: "Actions",
    subject: "Objet",
    recipient: "Destinataire",
    date: "Date",
    key: "ID",
    status: "Statut",
    channel: "Type",
  },
  status: {
    error: {
      label: "Erreur",
      color: "red",
    },
    processing: {
      label: "En cours d'envoi",
      color: "blue",
    },
    done: {
      label: "Envoyé",
      color: "green",
    },
    waiting: {
      label: "En attente",
      color: "orange",
    },
  },
};

export default history;
