import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// SERVICES
import templateService from "services/template";

// COMPONENTS
import { Layout, Menu } from "antd";
import * as Icon from "@ant-design/icons";

import PrivateLayout from "layouts/PrivateLayout";
import Select from "components/Select";
import Spinner from "components/Spinner";

import AddButton from "./templates/AddButton";
import Template from "./templates/Template";

export default function Templates() {
  const { t } = useTranslation();
  const location = useLocation();

  const [templates, setTemplates] = useState<Array<Template>>([]);
  const [fetching, setFetching] = useState(true);
  const [filterChannels, setFilterChannels] = useState<Array<Channel>>([]);
  const [templateData, setTemplateData] = useState<Template | null>(null);

  async function fetchTemplates() {
    await templateService.getAll().then((response) => {
      if (response.success) {
        setTemplates(response.value);
        setTemplateData(
          (() => {
            if (location.hash !== "") {
              const template = response.value.find(
                (item) => item.id === parseInt(location.hash.split("#")[1])
              );

              if (template !== undefined) return template;
            }

            return response.value[0];
          })()
        );
      }
    });

    setFetching(false);
  }

  useEffect(() => {
    fetchTemplates();
  }, []);

  const displayIcon = (channel: Channel) => {
    switch (channel) {
      case "email":
        return <Icon.MailOutlined />;
      case "slack":
        return <Icon.SlackOutlined />;
      case "sms":
        return <Icon.MobileOutlined />;
    }
  };

  function filterData(): Array<Template> {
    return templates.filter((item) =>
      filterChannels.length === 0 ? true : filterChannels.includes(item.channel)
    );
  }

  function add(template: Template) {
    setTemplates([...templates, template]);
    setTemplateData(template);
  }

  function update(template: Template) {
    setTemplates(
      templates.map((item) => (item.id === template.id ? template : item))
    );
  }

  return (
    <PrivateLayout title={t("pages.templates.title")}>
      <Layout>
        <Layout.Header
          style={{
            backgroundColor: "transparent",
            display: "flex",
            marginBottom: "1.5em",
            padding: 0,
          }}
        >
          <Select
            isMultiple={true}
            options={[
              { label: "Email", value: "email" },
              { label: "Slack", value: "slack" },
              { label: "Sms", value: "sms" },
            ]}
            placeholder="Channels"
            value={filterChannels}
            width="200px"
            onChange={(value) => setFilterChannels([...value])}
          />

          <AddButton onAdd={add} />
        </Layout.Header>

        <Layout.Content>
          <Layout>
            <Layout.Sider style={{ backgroundColor: "#fff" }} width={250}>
              <Spinner data={templates} spinning={fetching}>
                <Menu
                  selectedKeys={[
                    ...(templateData !== null
                      ? [templateData.id.toString()]
                      : templates.length > 0
                      ? [templates[0].id.toString()]
                      : []),
                  ]}
                  defaultOpenKeys={["sub1"]}
                  mode="inline"
                  style={{ minHeight: "200px" }}
                  theme="light"
                >
                  {filterData().map((template) => (
                    <Menu.Item
                      icon={displayIcon(template.channel)}
                      key={template.id}
                      onClick={() => setTemplateData(template)}
                    >
                      {template.name}
                    </Menu.Item>
                  ))}
                </Menu>
              </Spinner>
            </Layout.Sider>

            {templateData !== null && (
              <Template template={templateData} onUpdate={update} />
            )}
          </Layout>
        </Layout.Content>
      </Layout>
    </PrivateLayout>
  );
}
