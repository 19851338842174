import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Button, Form, Typography } from "antd";
import * as Icon from "@ant-design/icons";

import Select from "./Select";

type Props = {
  displayedValue?: string;
  editable?: boolean;
  error?: boolean;
  errorMessage?: string;
  label?: string;
  options: Array<{ label: string; value: string }>;
  placeholder?: string;
  style?: React.CSSProperties;
  value: string | undefined;

  onSave: (value: string) => void;
};

export default function SelectEdit(props: Props) {
  const { t } = useTranslation();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string | undefined>(props.value);

  useEffect(() => {
    setValue(props.value !== null ? props.value : "");
    setLoading(false);
    setEditing(false);
  }, [props.value]);

  function save() {
    setLoading(true);
    props.onSave(value || "");
  }

  return (
    <div style={props.style}>
      {props.label && <label style={{ fontWeight: 600 }}>{props.label}</label>}

      {!editing ? (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography.Paragraph style={{ margin: 0 }}>
              {props.value !== null
                ? props.displayedValue || props.value
                : t("form.undefined")}
            </Typography.Paragraph>

            {(props.editable ?? true) && (
              <Button
                icon={<Icon.EditOutlined />}
                type="text"
                onClick={() => {
                  setEditing(true);
                  setValue(props.value !== null ? props.value : "");
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <Form layout="vertical" style={{ display: "flex" }}>
          <Select
            isMultiple={false}
            options={props.options.map((option) => ({
              label: <span>{option.label}</span>,
              value: option.value,
            }))}
            placeholder={props.placeholder}
            value={value}
            onChange={(value) => setValue(value)}
          />

          <div style={{ display: "flex" }}>
            <Button
              icon={<Icon.CloseOutlined />}
              type="text"
              onClick={() => setEditing(false)}
            />
            <Button
              icon={<Icon.CheckOutlined />}
              loading={loading}
              style={{ marginRight: "1em" }}
              type="text"
              onClick={save}
            />
          </div>
        </Form>
      )}
    </div>
  );
}
