// COMPONENTS
import { Typography } from "antd";

import EditButton from "./channel/EditButton";
import RemoveButton from "./channel/RemoveButton";

type Props = {
  channel: ConfigurationSlack;
  disabled: boolean;

  onEdit: (channel: ConfigurationSlack) => void;
  onRemove: () => void;
};

export default function Channel(props: Props) {
  return (
    <div
      style={{
        alignItems: "center",
        borderTop: "1px solid rgba(0, 0, 0, .2)",
        display: "flex",
        padding: "10px 0",
      }}
    >
      <Typography.Text>{props.channel.name}</Typography.Text>

      <div style={{ marginLeft: "auto" }}>
        <EditButton channel={props.channel} onEdit={props.onEdit} />
        <RemoveButton
          channel={props.channel}
          disabled={props.disabled}
          onRemove={props.onRemove}
        />
      </div>
    </div>
  );
}
